export default {
  menus: {
    "001": "Application Management",
    "002": "Development Capability",
    "003": "Technical Document",
    "004": "App Launch",
    "005": "API Interface",
    "006": "SDK",
    "007": "Development Resource",// "Test Packages",
    "008": "Document",
  },
  devApi: {
    tabs: {
      '001': '全部',
    },
    breadcrumb: {
      "001": "Homepage",
      "002": "Development Capability",
      "003": "API Interface",
    },
    label: {
      "001": "Free",
      "002": "Charge",
    },
    operation: {
      "001": "API Document",
      "002": "Purchase traffic packets",
    },
  },
  devApp: {
    breadcrumb: {
      "001": "Homepage",
      "002": "Application management",
      "003": "Apps launch",
    },
    title: "Apps launch",
    addApp: "New applications added",
    table: {
      "001": "Application ICON",
      "002": "Application name",
      "003": "Version number",
      "004": "Review status",
      "005": "Application status",
      "006": "Pending submission",
      "007": "Review time",
      "008": "Operation",
    },
    stateOpts: {
      "001": "Pending submission",
      "002": "Reviewed",
      "003": "Pending review",
      "004": "Updates pending review",
      "005": "Update Review Rejected",
      "006": "Review not approved",
      "007": "Reason for rejection.",
    },
    statusOpts: {
      "001": "Launched",
      "002": "Ready for launching",
    },
    operation: {
      edit: "Edit",
      delete: "Delete",
      update: "Update",
      find: "View",
      release: "Release",
      unRelease: "UnRelease",
    },
    add: {
      title: "New Application",
    },
    edit: {
      title: "Application Details",
    },
    find: {
      title: 'checking Application',
    },
    note: {
      '001': 'Do you want to delete the application?',
      '002': 'Do you want to remove this application?',
      '003': 'Is this app available?',
    },
  },
  devSdk: {
    tabs: {
      '001': 'General',
      '002': 'Specific',
    },
    breadcrumb: {
      "001": "Homepage",
      "002": "Development Capability",
      "003": "SDK",
      "004": "SDK file download",
    },
    table: {
      "001": "File name",
      "002": "Format",
      "003": "Size (MB)",
      "004": "Number of downloads",
      "005": "Last update",
      "006": "Operation",
    },
    operation: {
      "001": "API Document",
      "002": "SDK Downloading",
      "003": "Download SDK",
      "004": "Document",
    },
  },
  testPkg: {
    breadcrumb: {
      "001": "Homepage",
      "002": "Development Capability",
      "003": "Test Package",
    },
    title: "System",
    table: {
      "001": "File name",
      "002": "Format",
      "003": "Size (MB)",
      "004": "Number of downloads",
      "005": "Last update",
      "006": "Operation",
    },
    operation: {
      "001": "Download",
    },
  },
  sdkPkg: {
    breadcrumb: {
      "001": "Homepage",
      "002": "Development Capability",
      "003": "SDK",
      "004": "SDK file download",
    },
    table: {
      "001": "File name",
      "002": "Format",
      "003": "Size (MB)",
      "004": "Number of downloads",
      "005": "Last update",
      "006": "Operation",
    },
    operation: {
      "001": "Download",
    },
  },
  addApp: {
    table: {
      "001": "Application name:",
      "002": "Version No. :",
      "003": "Version name:",
      "004": "Application package name:",
      "005": "Application category:",
      "006": "ICON:",
      "007": "Installation package:",
      "008": "Application Profile:",
      "009": "Test Account number:",
      "010": "Introduction picture/video:",
	  "011": "Updates",
    },
    placeholder: {
      "001": "Please enter the application name",
      "002": "Please enter the version number",
      "003": "Please enter the version name",
      "004": "Please enter the application package name",
      "005": "Please select Application category",
      "006": "Recommended resolution 512 x 512px, supports png image format, size 2M or less",
      "007": "Click or drag files to upload",
      "008": "Only apk files can be uploaded and the file size is less than 150M",
      "009": "Please enter the application profile",
      "010": "Please enter content",
	  "011": "Please enter updates",
	  },
    rule: {
      '001': "The installation package cannot be empty!",
      '002': "The application name cannot be empty!",
      '003': "The version number cannot be empty!",
      '004': "The version name cannot be empty!",
      '005': "The application package name cannot be empty!",
      '006': "The application category cannot be empty!",
      '007': "ICON cannot be empty!",
      '008': "The application description cannot be empty!",
      '009': "The presentation picture/video cannot be empty!",
    },
    operation: {
      "001": "Cancel",
      "002": "Save hold on submission",
      "003": "Submit for review",
    },
  },
  editApp: {
    table: {
      "001": "Application name:",
      "002": "Version No. :",
      "003": "Version name:",
      "004": "Application package name:",
      "005": "Application category:",
      "006": "ICON:",
      "007": "Installation package:",
      "008": "Application Profile:",
      "009": "Test Account number:",
      "010": "Introduction picture/video:",
    },
    note: {
      "001": "No installation package",
    },
  },
  verApp: {
    breadcrumb: {
      "001": "Homepage",
      "002": "Application Management",
      "003": "App Launch",
      "004": "Version Update",
    },
    addBtn: "New version",
    table: {
      "001": "Version number",
      "002": "Version name",
      "003": "Review status",
      "004": "Number of downloads",
      "005": "Submission time",
      "006": "Operation",
    },
    stateOpts: {
      "001": "Pending submission",
      "002": "Reviewed",
      "003": "Pending review",
      "004": "Published",
      "005": "Update review rejected",
      "006": "Rejected",
      "007": "Rejected reason:",
    },
    operation: {
      edit: "Edit",
      release: "Release",
      submit: "Submit for review",
    },
    note: {
      '001': 'Do you want to release this version? ',
      }
  },
  devHome: {
    title: "Real name authentication is required to become a developer before using related services",
    btn: "Head to Certification",
    operation: {
      "001": "Fill in the information",
      "002": "Submit for review",
      "003": "Pending review",
      "004": "Approved",
    },
  },
  articleDetail: {
    "001": "Update time:",
    "002": "Page views:",
  },
  mobile: {
    '001': 'Development document',
    '002': 'Drive test package',
    '003': 'Questions & Answers',
    list: {
      '001': 'article name',
      '002': 'author',
      '003': 'Page views',
      '004': 'Creation time',
      '005': 'Format',
      '006': 'Size (MB)',
      '007': 'Downloads',
      '008': 'Update time',
      }
    }
};
