export default {
  banner: {
    title: '服务支持中心',
    subTitle: '常见问题、服务工单，为用户提供便捷高效的服务支持',
  },
  article: {
    title: '常见问题',
    operation: {
      '001': '查看详情',
    },
    hotTitle: '热搜问题',
    hotKey: '热门关键词',
    history: '搜索历史',
  },
  order: {
    title: '服务工单',
    subTitle: '提交工单',
    note: {
      '001': '为必填项',
      '002': '提交工单成功',
    },
    table: {
      '001': '反馈类型',
      '002': '标题',
      '003': '产品名称',
      '004': '详情描述',
      '005': '上传附件',
      '006': '联系方式',
      '007': '提交工单',
    },
    rules: {
      '001': '请选择工单类型',
      '002': '请输入标题',
      '003': '请输入产品名称',
      '004': '请输入联系方式',
      '005': '详情描述不能为空',
    },
    placeholder: {
      '001': '请填写标题',
      '002': '请填写产品名称',
      '003': '请输入内容',
      '004': '请填写联系方式',
    }
  }
}