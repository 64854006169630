export default {
  database: {
    title: 'DataBase',
    '001': 'Equipment Quantity',
    '002': 'Purchased Application',
    '003': 'Administrative Account',
    '004': 'Unread Message',
  },
  platForm: {
    title: 'Management System',
    empty: "You haven't added a system yet",
    join: 'View',
    apply_try: 'Apply',
    cancel_try: 'Cancel',
    try: 'Try', 
    try_notice_content1: 'After applying, you can enter MDM system, if you need to register the device to use,',
    try_notice_content2: ' you need to contact your sales/pre-sales manager/agent to open the device quantity authorization.',
    try_notice_title: 'Notice'
  },
  recCase: {
    title: 'Recommended Solution',
  },
  notice: {
    title: "What's New",
    breadcrumb: {
      '001': 'Home',
      '002': 'List',
      '003': 'Detail'
    },
    table: {
      '001': 'Title',
      '002': 'Creation time',
      '003': 'Operation',
    },
    detail: {
      '001': 'Announcement Time: ',
    }
  },
  myApp: {
    title: 'My Apps',
    release: 'Release',
    unRelease: 'UnRelease',
  },
  recApp: {
    title: 'Recommended Application'
  },
  problem: {
    title: 'Frequently Asked Questions',
    more: 'See More'
  },
  banner: {
    empty: 'No images added yet ~'
  },
  view: 'view',
  workbench: {
    title: 'Workbench',
    myPlatForm: 'My System',
    myData: 'My DataBase',
    myApp: 'My Application',
  }
}