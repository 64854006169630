export default {
    A0001: 'Log in and view the information',
    A0002: 'Already exists',
    A0003: 'The user name already exists',
    A0004: 'Login timeout',
    A0005: 'The user name or mobile phone number already exists',
    A0006: 'The mobile phone number has been registered. Please try with other numbers',
    A0007: 'The account has been registered and is waiting for verification',
    A0008: 'Verification code error',
    A0009: 'The user has been authenticated in real name',
    A0010: 'The user is being authenticated',
    A0011: 'Only one account can be authenticated with the same ID number',
    A0012: 'The old login password is incorrect',
    A0013: 'The enterprise has been authenticated and cannot be re-authenticated!',
    A0014: 'The enterprise is in certification and cannot be re-certified!',
    A0015: 'Failed to modify',
    A0016: 'Failed to retrieve password!',
    A0017: 'I‘m sorry! You do not have permission to access this resource.',
    A0018: 'Multiple company data are queried based on the company name. Check whether redundant data exists in the customer information and clear it.',
    A0019: 'Failed to associate the account with customer information. Please try again, or contact O&M personnel.',
    A0020: 'A menu exists in the platform system. Please delete the menu before deleting it.',
    A0021: 'The superior department cannot be its own or a child',
    A0022: 'The department has subdepartments and cannot be operated',
    A0023: 'The department is in use and cannot be operated',
    A0024: 'Enable the upper department first',
    A0025: 'Failed to start a task',
    A0026: 'Failed to suspend the task',
    A0027: 'Failed to obtain the user role',
    A0028: 'This menu has child nodes and cannot be deleted',
    A0029: 'Please disable the submenu first',
    A0030: 'Delete the application version step by step from the highest version in the list. After the highest version is deleted, the application version is reverted to the previous version.',
    A0031: 'Please disable subcategories first',
    A0032: 'The category has subcategories. Please delete the subcategories first!',
    A0033: 'The customer has been associated with an account. Please delete the associated account.',
    A0034: 'Failed to send the SMS verification code',
    A0035: 'This account has not been audited, please wait for verification',
    A0036: 'Delete the account has subaccounts. Please delete the subaccounts first.',
    A0037: 'An approved version is not released',
    A0038: 'This account no permission to view',
    A0039: 'Must pass the test first',
    A0040: 'The highest version has been approved and does not need to be approved',
    A0041: 'Publication submission failed. Please refresh and try again or contact maintenance personnel',
    A0042: 'The application name already exists',
    A0043: 'The department name already exists',
    A0044: 'The scheduled task already exists',
    A0045: 'The permission identifier already exists',
    A0046: 'Unique key already exists',
    A0047: 'The mobile phone number already exists',
    A0048: 'The worker ID already exists',
    A0049: 'The account does not exists',
    A0050: 'Incorrect password',
    A0051: 'Too many password errors, please try again in 5 minutes',
    A0052: 'Current IP address cannot log in',
    A0053: 'Account has been disabled',
    A0054: 'Account number has expired',
    A0055: 'SMS verification code has expired',
    A0056: 'Add failure',
    A0057: 'Send failed',
    A0058: 'Uploaded file is empty',
    A0059: 'Internal error',
    A0060: 'Email already exists',
    A0061: 'User name or email already exists',
    A0062: 'This email address has already been registered, please do not register twice',
    A0063: 'Mail delivery failed',
    A0064: 'Failed to upload file',
    A0065: 'Name already exists',
    A0066: 'Data name must not be repeated',
    A0067: 'MDM Synchronization failure',
    A0068: 'Download failed',
    A0069: 'Export failed',
    A0070: 'ApiKey error',
    A0071: 'Incorrect encryption information',
    A0072: 'Account unavailable',
    A0073: 'Requested link has expired',
    A0074: 'Cell phone number does not exists',
    A0075: 'Verification code acquisitions is too frequent, please try again in 1 hour',
    A0076: 'This e-mail account is not registered',
    A0077: 'No associated document or not visible',
    A0078: 'The resource does not exist or is not visible',
    A0079: 'The operation is too frequent, please try again later',

    B0001: 'Necessary parameters are missing',
    B0002: 'The role is referenced and cannot be deleted',
    B0003: 'Your IP address is not in the whitelist',
    B0004: 'The parameter is invalid',
    B0005: 'Request error',
    B0006: 'Upload file size exceeds limit',
    B0007: 'Picture format is wrong',
}