import Vue from "vue";
import i18next from "i18next";
import VueI18Next from "@panter/vue-i18next";
import VueCookie from "vue-cookie";
import langResource from "./lang";
import { getInitI18n } from "./config/global_val";

Vue.use(VueI18Next);

i18next.init({
  // lng: VueCookie.get("i18next") || getInitI18n() || "zh",
  lng: getInitI18n() || "zh",
  useCookie: true,
  resources: langResource,
  interpolation: {
    prefix: "{",
    suffix: "}"
  },
  detection: {
    caches: ["cookie"],
    cookieMinutes: 30 * 24 * 60,
    lookupQuerystring: "lang",
    lookupFromPathIndex: 0
  }
});

export default new VueI18Next(i18next);
