export default {
  banner: {
    title: "Solution Center",
    subTitle: "Gather industry solutions and enable end-product in all aspects",
  },
  solutionone: {
    title: "Solution query",
    operation: {
      "001": "Detail",
      '002': "Share",
    },
  },
  equipmentPlan: {
    title: "Device applicable scheme query",
  },
  search: {
    "001": "First Level Catalogue of Products",
    "002": "Secon Level Catalogue of Products",
  },
  solutionDetail: {
    breadcrumb: {
      "001": "Solution",
      "002": "Recommended scheme",
      "003": "E-commerce Industry",
      "004": "Logistics Industry",
      "005": "Retail industry",
      "006": "Medical industry",
      "007": "Manufacturing industry",
      "008": "Public service",
      "009": "Other industry",
    },
    detail: {
      "001": "Solution price:",
      "002": "yuan",
      "003": "Contact pre-sale consultation",
      "004": "Service: ",
      "005": "Solution obtain docking",
      "006": "Provided by Seuic",
    },
    case: {
      title: "Solution Introduction",
    },
    equipment: {
      title: "Applicable device",
    },
    caseLoad: {
      title: "Data download",
      table: {
        "001": "Material name",
        "002": "Format",
        "003": "Size (MB)",
        "004": "Number of downloads",
        "005": "Last update",
        "006": "Operation",
      },
    },
  },
  checkingData: {
    breadcrumb: {
      "001": "Applicable device",
      "002": "Product Information",
    },
    search: {
      "001": "Product line",
      '002': 'Product type',
    },
    table: {
      "001": "Material name",
      "002": "Format",
      "003": "Size (MB)",
      "004": "number of downloads",
      "005": "Last update",
      "006": "Operation",
    },
  },
  mobile: {
    '001': 'Consult',
  }
};
