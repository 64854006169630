// import VueCookie from "vue-cookie";
// import { setLang } from '@/api/common';

// 国际版白名单
const isOverSeas = [
    'en.iot.seuic.com', 
    'cdn.iot.seuic.com', 
    '192.168.41.151:803',
    // 'localhost:8888',
].includes(window.location.host);

// 获取语言环境 0中文 1英文
export const getInitI18n = () => {
    let lang = 'zh';
    if(!!isOverSeas) return 'en'; // 国际版白名单默认设置成英文
    // var jsSrc = (navigator.language || navigator.browserLanguage).toLowerCase();
    // if (jsSrc.indexOf("zh") >= 0) {
    // lang = "zh";
    // // 假如浏览器语言是中文
    // } else if (jsSrc.indexOf("en") >= 0) {
    // lang = "en";
    // // 假如浏览器语言是英文
    // } else if (jsSrc.indexOf("ja") >= 0) {
    // lang = "jp";
    // // 假如浏览器语言是其它语言
    // } else if (jsSrc.indexOf("ru") >= 0) {
    // lang = "ru";
    // }
    // let data = -1;
    // if (lang === "zh") {
    //   data = 0;
    // } else if (lang === "en") {
    //   data = 1;
    // }
    // if (data !== -1) {
    //   setLang(data).then( response => {
    //       console.log(response);
    //     }).catch(response => {
    //     errorInfo(`code.${response.code}`);
    //   })
    // }

    return lang;
};

// 语言版本
// const getLang = VueCookie.get("i18next") || getInitI18n() || "zh";
const getLang = getInitI18n() || "zh";

// 是否是移动端
export function isMobile() {
    return /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i.test(
      navigator.userAgent
    );
};

// navigator.userAgent判断机型
// export const BrowserInfo = {      
//     isAndroid: Boolean(navigator.userAgent.match(/android/ig)), // 是否是Android浏览器   
//     isIphone: Boolean(navigator.userAgent.match(/iphone|ipod|iOS/ig)), // 是否是苹果浏览器
//     isIpad: Boolean(navigator.userAgent.match(/ipad/ig)), // 是否是 IPad 浏览器
//     isIpad: Boolean(navigator.userAgent.match(/Linux/ig)), // 是否是Linux平台浏览器
//     isWeixin: Boolean(navigator.userAgent.match(/MicroMessenger/ig)), // 是否是微信平台浏览器
//     isAli: Boolean(navigator.userAgent.match(/AlipayClient/ig)), // 是否是支付宝平台浏览器
//     isPhone: Boolean(/(iPhone|iPad|iPod|iOS|Android)/i.test(navigator.userAgent)) // 是否是手机端
// }

// navigator.platform判断
// if(/Win/i.test(navigator.platform)){
//     document.write("这是Windows操作系统平台");
// }
// if(/Mac/i.test(navigator.platform)){
//     document.write("这是Mac操作系统平台");
// }
// if(/Linux/i.test(navigator.platform)){
//     document.write("这是Linux操作系统平台（包括安卓系统）");
// }
// if(/iOS/i.test(navigator.platform)){
//     document.write("这是iOS操作系统平台");
// }
  

export default {
    getLang: getLang, 
    isOverSeas: isOverSeas,
    isMobile: isMobile(),
}