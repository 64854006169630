import request from '@/utils/request';
import api from './api';

// dev-apps
export function getAppsList(data) {
  return request({
    url: api.Appslist, 
    data, 
    method: 'get'
  })
}

export function deletedApps(data) {
  return request({
    url: api.Del_status,
    data, 
    method: 'get'
  })
}

export function getAppCateList(data) {
  return request({
    url: api.AppCateList,
    data, 
    method: 'get'
  })
}

export function getFiles(data) {
  return request({
    url: api.Files,
    data, 
    method: 'get'
  })
}

export function submitApps(data) {
  return request({
    url: api.SubmitAppps,
    data, 
    method: 'post'
  })
}

export function getAppsVerList(data) {
  return request({
    url: api.Appsverlist,
    data, 
    method: 'get'
  })
}

export function submitUpdate(data) {
  return request({
    url: api.SubmitUpdate,
    data, 
    method: 'post'
  })
}

export function release(data) {
  return request({
    url: api.Fabu,
    data, 
    method: 'post'
  })
}

export function submitAppsVer(data) {
  return request({
    url: api.SubmitApppsVer,
    data, 
    method: 'post'
  })
}

// dev-api
export function getApiCare(data) {
  return request({
    url: api.Getapicare,
    data, 
    method: 'get'
  })
}

export function getApiList(data) {
  return request({
    url: api.ApiList,
    data, 
    method: 'get'
  })
}

export function getCategory(data) {
  return request({
    url: api.Getcategroy,
    data, 
    method: 'get'
  })
}

export function getSdkCategory(data) {
  return request({
    url: api.Wendang,
    data, 
    method: 'get'
  })
}

export function getParameter(data) {
  return request({
    url: api.GetParameter,
    data, 
    method: 'get'
  })
}

// dev-sdk
export function getSdk(data) {
  return request({
    url: api.SdkList,
    data, 
    method: 'get'
  })
}

export function getSdkModelList(data) {
  return request({
    url: api.Sdkmodellist,
    data, 
    method: 'get'
  })
}

export function getSdkProduct(data) {
  return request({
    url: api.SdkProduct,
    data, 
    method: 'get'
  })
}

export function getSdkProductNull(data) {
  return request({
    url: api.SdkProductNull,
    data, 
    method: 'get'
  })
}

export function getFileList(data) {
  return request({
    url: api.FileList,
    data, 
    method: 'get'
  })
}


// test-package
export function getDiction(data) {
  return request({
    url: api.Diction,
    data, 
    method: 'get'
  })
}

export function getSelectList(data) {
  return request({
    url: api.SelectList,
    data, 
    method: 'get'
  })
}

export function getArticleById(data) {
  return request({
    url: api.FindArticle,
    data, 
    method: 'get'
  })
}
