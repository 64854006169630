const xs = '600'; // 600px
const sm = '768'; // 768px
const md = '992'; // 992px
const lg = '1200'; // 1200px
const xl = '1920'; // 1920px

const mobile = {
    // 当被绑定的元素插入到DOM中时
    inserted: function (el, binding) {
      // 获取元素的初始显示状态
      const initialDisplay = window.getComputedStyle(el).display
  
      // 监听窗口大小变化事件
      window.addEventListener('resize', function () {
        // 获取当前窗口宽度
        const currentWidth = window.innerWidth
  
        // 如果当前窗口宽度小于768px，且元素的初始显示状态为block，则执行隐藏操作
        if (currentWidth <= xs && initialDisplay === 'block') {
          el.style.display = 'none'
        }
      })
    }
}

export default {
    mobile
}