<template>
    <div class="container" style="height: 100%;">
        <header-login></header-login>

        <div class="box-1">
            <div class="box-l1"></div>
            <div class="box-right">
                <!-- 登录表单 -->
                <div class="box-r1" v-if="loginShow">
                    <div style="display: flex; flex-direction: column; align-items: center;">
                        <img src="@/assets/images/login/logintop.png" v-if="!$global.isOverSeas">
                        <img src="@/assets/images/logo_blue.jpg" style="margin: 20px 0; width: 150px; height: 45px;" v-else>
                        <span class="box-s1">{{$t('common.header.title')}}</span>
                    </div>

                    <div class="box-r1-1">
                        <img src="@/assets/images/login/login2.png" class="zhanghao" style="width: 22px">
                        <el-input :placeholder="$t('user.login.rule.account')" v-model="username" @keyup.enter.native="login"></el-input>
                    </div>

                    <div class="box-r1-1">
                        <img src="@/assets/images/login/login33.png" class="zhanghao" style="width: 22px">
                        <el-input :placeholder="$t('user.login.rule.password')" v-model="password" @keyup.enter.native="login" show-password></el-input>
                    </div>

                    <div class="box-r1-1" hidden>
                        <img src="@/assets/images/login/login33.png" class="zhanghao" style="width: 22px">
                        <el-input :placeholder="$t('user.login.rule.password')" v-model="oldPassword" @keyup.enter.native="login" show-password></el-input>
                    </div>

                    <!-- <div class="box-r1-1 r1-2">
                        <img src="@/assets/images/login/login77.png" class="zhanghao" style="width: 22px">
                        <el-input :placeholder="$t('user.login.rule.verifyCode')" maxlength="4" @keyup.enter.native="login" v-model="code"></el-input>
                        <img class="code-img" :src="verifyCode" @click="refreshCode"/>
                    </div> -->

                    <div class="box-r1-s">
                        <div class="box-r1-sl">
                            <span class="box-s4" v-if="!choose" @click="chooses()"></span>
                            <img src="@/assets/images/login/login4.png" style="width: 15px;height: 15px;margin: 0 4px" v-if="choose" @click="chooses()">
                            <span class="box-s2">{{$t('user.login.rememberPwd')}}</span>
                            <img src="@/assets/images/login/zhixian.png" style="width: 1px;height: 16px;margin: 0 4px;">
                            <span class="box-s2" style=" color: #0075C1;" @click="forget(false)">{{$t('user.login.forgetPwd')}}</span>
                        </div>
                        <div class="box-r1-sr">
                            <span class="box-s3" @click="$router.replace('/register')">{{$t('user.login.register')}}</span>
                        </div>
                    </div>
                    <el-button class="loginbutton" @click="login">{{$t('user.login.login_btn')}}</el-button>
                </div>
                <!-- 忘记密码表单 -->
                <forget-password v-else @forget="forget(true)"></forget-password>
            </div>
        </div>

    </div>
</template>

<script>
import { getLoginUser, login, logout } from '@/api/user';
import ForgetPassword from '@/components/forgetPassword.vue';
import Complete from '@/components/content/Complete.vue';
import HeaderLogin from '@/components/content/headerLogin.vue';
import CryptoJS from 'crypto-js';
import { baseUrl, basePath} from '@/config/env';
import { getQueryString } from '@/utils/tools';
import { successInfo,errorInfo,warnInfo,infoInfo } from '@/utils';
export default {
  components: { ForgetPassword, Complete, HeaderLogin },
  data() {
    return {
        username: "",
        password: "",
        code: "",
        oldPassword: "",
        // verifyCode: "",
        choose: true,
        loginShow: true,
        centerdialogvisible: false,
        complete:{
            title: this.$t('user.login.login_success'),
            subtitle:"",
            button:this.$t('common.ok_text'),
            login:true,
            pic: require('../assets/images/popup/complete.png')
        },
        basePath: basePath,
        redirect: undefined,
        otherQuery: {}
    }
  },
  watch: {
    $route: {
      handler: function(route) {
        const query = route.query
        if (query) {
          this.redirect = query.redirect
          this.otherQuery = this.getOtherQuery(query)
        }
      },
      immediate: true
    }
  },
  methods: {
    // 刷新验证码
    // refreshCode () {
    //     let timestamp = new Date().getTime();
    //     let src = baseUrl + "/valid_code/get_code_img?t=" + timestamp;
    //     this.verifyCode = src;
    //     console.log('验证码刷新成功');
    // },
    chooses () {
        this.choose = !this.choose;
    },
    // 登录
    login () {
        let username = this.username;
        if (username === '') return errorInfo('user.login.rule.account');
        let password = this.password;
        if (password === '') return errorInfo('user.login.rule.password');
        let key = "1234567812345678";
        let text = password;
        key = CryptoJS.enc.Utf8.parse(key);
        let encryptedData = CryptoJS.AES.encrypt(text, key, {
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7
        });
        let loginPassword = encryptedData + "";
        loginPassword = loginPassword.replace(/\+/g, "_")

        password = loginPassword;
        // let code = this.code;
        // if (!code) return errorInfo('user.login.rule.verifyCode');
        login({username: username, password: password, /* code: code */}).then(res => {
            if (res.rescode === 200) {
                this.centerdialogvisible = true;
                let obj = {};
                obj.username = username;
                let cookies_str = JSON.stringify(obj);
                this.$cookie.set(process.env.VUE_APP_USERINFO, cookies_str, {expires: 1, path: '/'});
                setTimeout(() => {
                    if (!getQueryString('apiKey')) {
                        this.$router.replace({ path: this.redirect || '/', query: this.otherQuery })
                    } else {
                        window.location.href = baseUrl + `/loginRedirect?apiKey=${getQueryString('apiKey')}&redirectUrl=${getQueryString('redirectUrl')}`;
                    }
                }, 1000);
            } else {
                this.code = null;
                // this.refreshCode();
            }
        }).catch(() => {
            this.code = null;
            // this.refreshCode();
        })
    },
    // 忘记密码
    forget(type){
        // if(this.$global.isOverSeas) return warnInfo(this.$t('common.note.031'));
        this.loginShow = type;
    },
    // 获取用户信息
    getLoginUser() {
      getLoginUser().then(res => {
        if (res.rescode === 200) {
            if (!getQueryString('apiKey')) {
                this.$router.replace({ path: this.redirect || '/', query: this.otherQuery })
            } else {
                if(!!getQueryString('type') && getQueryString('type') == 'logout') {
                    logout().then(res => {
                        if (res.rescode == 200) {
                            this.$store.commit('setUserInfo', ''); // 清除用户信息
                            this.$store.commit('setMenus', '');  // 清除权限菜单
                        }  
                    })
                } else {
                    window.location.href = baseUrl + `/loginRedirect?apiKey=${getQueryString('apiKey')}&redirectUrl=${getQueryString('redirectUrl')}`;
                }
            }
        } else {
            // 校验用户是否已经登录，若已登录直接调转到后台页面
            let cookies_str = this.$cookie.get(process.env.VUE_APP_USERINFO);
            if (cookies_str && cookies_str != 'undefined' && typeof (cookies_str) != 'undefined') {
                let obj = JSON.parse(cookies_str);
                this.username = obj.username;
                this.password = "";
            }
        }
      }, error => {})
    },
    getOtherQuery(query) {
      return Object.keys(query).reduce((acc, cur) => {
        if (cur !== 'redirect') {
          acc[cur] = query[cur]
        }
        return acc
      }, {})
    }
  },
  created() {
    this.getLoginUser();
    // this.refreshCode();
  }
}
</script>

<style src="@/style/pc/login.less" lang="less" scoped></style>

<style lang="less" scoped>
@media screen and (max-device-width: @xs) {
    @import '@/style/mobile/login.less';
}
</style>