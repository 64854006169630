import request from '@/utils/request';
import api from './api';

export function login(data) {
  return request({
    url: api.Login,
    data,
    method: 'post',
    headers: {'Content-Type': 'application/x-www-form-urlencoded'}
  })
}

export function register(data) {
  return request({
    url: api.Register,
    data,
    method: 'post'
  })
}

export function logout(data) {
  return request({
    url: api.Logout,
    data,
    method: 'post',
    headers: {'Content-Type': 'application/x-www-form-urlencoded'}
  })
}

export function sendCodeWithEmail(data) {
  return request({
    url: api.SendCodeWithEmail,
    data,
    method: 'post'
  })
}

export function checkEmail(data) {
  return request({
    url: api.CheckEmail,
    data,
    method: 'post'
  })
}


export function sendCode(data) {
  return request({
    url: api.SendCode,
    data,
    method: 'post'
  })
}

export function checkPhone(data) {
  return request({
    url: api.CheckPhone,
    data,
    method: 'post'
  })
}


export function updatePwd(data) {
  return request({
    url: api.UpdatePwd,
    data,
    method: 'post'
  })
}

export function updatePassword(data) {
  return request({
    url: api.UpdatePassword, 
    data, 
    method: 'get'
  })
}

export function getLoginUser(data) {
  return request({
    url: api.LoginUser, 
    data,
    method: 'get'
  })
}

export function getUser(data) {
  return request({
    url: api.GetUser, 
    data,
    method: 'get'
  })
}

export function updateUser(data) {
  return request({
    url: api.UpdateUser,
    data,
    method: 'post'
  })
}


export function verifyUser(data) {
  return request({
    url: api.Verify,
    data,
    method: 'post'
  })
}

export function verification(data) {
  return request({
    url: api.Verification,
    data,
    method: 'post'
  })
}

export function getCustomer(data) {
  return request({
    url: api.GetCustomer,
    data,
    method: 'get'
  })
}

export function getAreaElement(data) {
  return request({
    url: api.GetAreaElement,
    data,
    method: 'get'
  })
}

export function getLoginLog(data) {
  return request({
    url: api.GetloginLog,
    data,
    method: 'get'
  })
}


export function updateMsgState(data) {
  return request({
    url: api.UpdateMsgState,
    data,
    method: 'get'
  })
}

export function getMsgList(data) {
  return request({
    url: api.GetMsgList,
    data,
    method: 'get'
  })
}

export function getOrder(data) {
  return request({
    url: api.GetOrder,
    data,
    method: 'get'
  })
}

export function getOrderList(data) {
  return request({
    url: api.GetOrderList,
    data,
    method: 'get'
  })
}

export function submitMsg(data) {
  return request({
    url: api.SubmitMsg,
    data,
    method: 'post'
  })
}

// 添加收藏
export function addFavorite(data) {
  return request({
    url: api.AddFavorite,
    data,
    method: 'post'
  })
}

// 取消收藏
export function deleteFavorite(data) {
  return request({
    url: api.DeleteFavorite,
    data,
    method: 'get'
  })
}

// 获取收藏
export function getFavoriteList(data) {
  return request({
    url: api.GetFavoriteList,
    data,
    method: 'get'
  })
}

// 置顶收藏
export function topFavorite(data) {
  return request({
    url: api.TopFavorite,
    data,
    method: 'post'
  })
}