export default {
  uploadNote: '若您有相关软件需要上架东集应用市场，提交相关材料。我们将与您联系审核完成后即可分发您的应用。',
  upload: '立即上传',
  submitNote: '若您有相关软件需要进行适配在东集设备上使用，请与我们联系，并提供设备型号、软件及开发者名称。我们进行可行性评估后将与您联系反馈评估结果及适配所需周期。',
  submit: '提交软件适配申请',
  banner: {
    title: '应用市场',
    subTitle1: '丰富的生态软件，为终端产品进行全方位赋能',
    subTitle2: '更多应用软件请在东集设备中的应用商店下载',
  },
  ecology: {
    title: '生态软件',
    label: '收费',
    operation: {
      '001': '下载',
      '002': '查看详情',
    }
  },
  equipment: {
    title: '适配软件查询',
    tabs: {
      '001': '适用设备',
    }
  },
  appMall: {
    breadcrumb: {
      '001': '应用市场',
    },
    detail: {
      '001': '收费类型：',
      '002': '免费',
      '003': '收费',
      '004': '软件类型：推荐应用',
      '005': '软件类型：终端工具软件',
      '006': '软件类型：ISV生态应用',
      '007': '版本名称：',
      '008': '应用包名：',
      '009': '立即咨询',
      '010': '安装包下载',
    },
    appDetail: {
      title: '应用详情'
    },
    contact: {
      '001': '请与我们联系',
      '002': '联系人员',
      '003': '汪经理',
      '004': '联系方式',
      '005': '18362969617',
      '006': '联系邮件',
      '007': 'wangchunrong@seuic.com',
      '008': '关  闭',
    },
    appDownload: {
      title: '资料下载',
    }
  },
}