<template>
  <div class="container">
    <!-- 手机号验证 -->
    <div class="box-r1" v-if="modelIndex == 1 && !$global.isOverSeas">
      <div style="display: flex; flex-direction: column; align-items: center;">
        <img src="@/assets/images/login/logintop.png" v-if="!$global.isOverSeas">
        <img src="@/assets/images/logo_blue.jpg" style="margin: 20px 0; width: 150px; height: 45px;" v-else>
        <span class="box-s1">{{$t('user.forget.001')}}</span>
      </div>

      <div class="box-r1-1">
        <p style="margin-bottom: 10px;">{{$t('user.forget.001')}}</p>
        <el-input :placeholder="$t('user.forget.rule.001')" v-model="phone" prefix-icon="el-icon-mobile-phone"></el-input>
      </div>

      <div class="box-r1-1">
        <el-input :placeholder="$t('user.forget.rule.002')" v-model="codes" prefix-icon="el-icon-message">
          <!-- <el-button slot="append" @click="sendCode('sms')">{{$t('user.forget.002')}}</el-button> -->
          <el-button slot="append" @click="sendCode('sms')" :disabled="isSend">
              <span v-if="!isSend">{{$t('user.forget.002')}}</span>
              <span v-else>{{ $t('user.register.verifyCode_btn_again') + `(${seconds})` }}</span>
          </el-button>
        </el-input>
      </div>

      <div style="width: 100%; margin: 20px 0;display: flex; align-items: center;">
        <span class="box-s2">{{$t('user.forget.003')}}</span>
        <span class="box-s2" style="margin-left: 5px;"><a href="javascript:void(0)" @click="loginshow">{{$t('user.forget.004')}}</a></span>
      </div>
      <button class="loginbutton" @click="nextTab(2, 'sms')">{{$t('user.forget.006')}}</button>
    </div>
    <!-- 邮箱验证 -->
    <div class="box-r1" v-if="modelIndex == 1 && $global.isOverSeas">
      <div style="display: flex; flex-direction: column; align-items: center;">
        <img src="@/assets/images/login/logintop.png" v-if="!$global.isOverSeas">
        <img src="@/assets/images/logo_blue.jpg" style="margin: 20px 0;width: 150px; height: 45px;" v-else>
        <span class="box-s1">{{$t('user.forget.001')}}</span>
      </div>

      <div class="box-r1-1">
        <p style="margin-bottom: 10px;">{{$t('user.forget.001')}}</p>
        <el-input :placeholder="$t('user.forget.rule.009')" v-model="email" prefix-icon="el-icon-mobile-phone"></el-input>
      </div>

      <div class="box-r1-1">
        <el-input :placeholder="$t('user.forget.rule.010')" v-model="codes"  prefix-icon="el-icon-message">
          <!-- <el-button slot="append" @click="sendCode('email')">{{$t('user.forget.rule.011')}}</el-button> -->
          <el-button slot="append" @click="sendCode('email')" :disabled="isSend">
              <span v-if="!isSend">{{$t('user.forget.002')}}</span>
              <span v-else>{{ $t('user.register.verifyCode_btn_again') + `(${seconds})` }}</span>
          </el-button>
        </el-input>
      </div>

      <div style="width: 100%; margin: 20px 0;display: flex;align-items: center;">
        <span class="box-s2">{{$t('user.forget.003')}}</span>
        <span class="box-s2" style="margin-left: 5px;"><a href="javascript:void(0)" @click="loginshow">{{$t('user.forget.004')}}</a></span>
      </div>
      <button class="loginbutton" @click="nextTab(2, 'email')">{{$t('user.forget.006')}}</button>
    </div>
    <!-- 重新输入密码 -->
    <div class="box-r1" v-if="modelIndex == 2">
      <div style="display: flex; flex-direction: column; align-items: center;">
        <img src="@/assets/images/login/logintop.png" v-if="!$global.isOverSeas">
        <img src="@/assets/images/logo_blue.jpg" style="margin: 20 0;width: 150px; height: 45px;" v-else>
        <span class="box-s1">{{$t('user.forget.001')}}</span>
      </div>

      <div class="box-r1-1">
        <p style="margin-bottom: 10px;">{{$t('user.forget.005')}}</p>
        <img src="@/assets/images/login/login33.png" class="zhanghao" style="width: 22px">
          <el-input :placeholder="$t('user.forget.rule.003')" v-model="password" show-password>
        </el-input>
      </div>

      <div class="box-r1-1">
        <img src="@/assets/images/login/login33.png" class="zhanghao" style="width: 22px">
        <el-input :placeholder="$t('user.forget.rule.004')" v-model="newpassword" show-password>
        </el-input>
      </div>

      <div style="margin: 20px 0;display: flex; align-items: center;">
        <span class="box-s2">{{$t('user.forget.003')}}</span>
        <span class="box-s2"><a href="javascript:void(0);" @click="$router.replace('/portal/login')">{{$t('user.forget.004')}}</a></span>
      </div>
      
      <button class="loginbutton" v-if="!$global.isOverSeas" @click="szhi('sms')">{{$t('user.forget.006')}}</button>
      <button class="loginbutton" v-else @click="szhi('email')">{{$t('user.forget.006')}}</button>
    </div>

    <complete :centerdialogvisible="centerdialogvisible" @buttons="loginshow" :complete="complete"></complete>
  </div>
</template>
<script>
import Complete from './content/Complete.vue';
import { sendCode, checkPhone, sendCodeWithEmail, checkEmail, updatePwd } from '@/api/user';
import { successInfo,errorInfo,warnInfo,infoInfo } from '@/utils';
import CryptoJS from 'crypto-js';
const phoneValid = /^(?:(?:\+|00)86)?1\d{10}$/;
const emailValid = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export default {
  props: {forgetpassword: Object},
  components: {Complete},
  data() {
    return {
      email:"",
      phone:"",
      codes:"",
      password:"",
      newpassword:"",
      modelIndex:"1",
      centerdialogvisible:false, //完成
      complete:{
        title: this.$t('user.editPwd.complete.001'),
        subtitle: this.$t('user.editPwd.complete.002'),
        button: this.$t('user.editPwd.complete.003'),
        login: true,
        pic: require("@/assets/images/popup/complete.png")
      },

      isSend: false, // 是否发送状态
      seconds: 30, // 默认值：30秒，读秒
      timer: null, // 
    }
  },
  methods: {
    /**
     * type: sms / email
     * @param {*} type 
     */
    sendCode(type){
      let data = {};
      data.type = 1;
      if(type === 'sms') { // sms发送验证
        data.phone = this.phone;
        if(!data.phone) return warnInfo('user.forget.rule.001')
        if(!phoneValid.test(data.phone)) return warnInfo('user.register.rule.012'); //
        this.readSecond(); // 读秒
        sendCode(data).then(res => {
          if (res.rescode == 200) {
            successInfo('common.note.003');
          }
        }, error => {})
      } else if(type === 'email'){ // email邮箱验证
        data.email = this.email;
        if(!data.email) return warnInfo('user.forget.rule.009')
        if(!emailValid.test(data.email)) return warnInfo('user.register.rule.013'); //
        this.readSecond(); // 读秒
        sendCodeWithEmail(data).then(res => {
          if (res.rescode == 200) {
            successInfo('common.note.003');
          }
        },error => {})
      }
    },
    /**
     * index: 2， type: sms / email
     * @param {*} index 
     * @param {*} type 
     */
    nextTab(index, type){
      if(type === 'sms') { // 手机号验证
        if (this.phone == null || this.phone == ''){
        errorInfo('user.forget.rule.005');
          return false;
        }
        if (this.codes == null || this.codes == ''){
          errorInfo('user.forget.rule.006');
          return false;
        }
        let data = {phone:this.phone,codes:this.codes}

        checkPhone(data).then(res => {
          if (res.rescode === 200) {
            this.modelIndex = index;
          }
        }).catch(e => {
          // errorInfo('common.note.002');
        })
      } else if(type === 'email') { // 邮箱验证
        if (this.email == null || this.email == ''){
          errorInfo('user.forget.rule.005');
          return false;
        }
        if (this.codes == null || this.codes == ''){
          errorInfo('user.forget.rule.006');
          return false;
        }
        let data = {email:this.email,codes:this.codes}

        checkEmail(data).then(res => {
          if (res.rescode === 200) {
            this.modelIndex = index;
          }
        }).catch(e => {
          // errorInfo('common.note.002');
        })
      }
    },
    /**
     * type: sms / email
     * @param {*} type 
     */
    szhi(type){
      let password = this.password;
      if (password ==null || password == ''){
        errorInfo('user.forget.rule.007');
        return false;
      }else if (password.length < 6 || password.length > 20){
        errorInfo('user.forget.rule.003');
        return false;
      }
      if (this.newpassword ==null || this.newpassword == ''){
        errorInfo('user.forget.rule.004');
        return false;
      }else if (this.password != this.newpassword){
        errorInfo('user.forget.rule.008');
        return false;
      }

      let key = "1234567812345678";
      let text = password;
      key = CryptoJS.enc.Utf8.parse(key);
      let encryptedData = CryptoJS.AES.encrypt(text, key, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
      });
      let loginPassword = encryptedData + "";
      loginPassword = loginPassword.replace(/\+/g, "_");

      let datas = {};
      if(type === 'sms') {
        datas = {phone:this.phone,password:loginPassword}
      } else if(type === 'email') {
        datas = {email:this.email,password:loginPassword}
      }

      updatePwd(datas).then(res => {
        if (res.rescode === 200) {
          this.centerdialogvisible = true;
        } else {
          errorInfo(res.msg);
        }
      }).catch(e => {
        errorInfo('common.note.002');
      })
    },
    loginshow(){
      this.$emit("forget");
    },
    /**
     * 读秒
     */
    readSecond() {
      this.isSend = true;
      this.timer = window.setInterval(() => {
          -- this.seconds;
          if(this.seconds === 0) {
              window.clearInterval(this.timer);
              this.seconds = 30;
              this.isSend = false;
          }
      }, 1000)
    }
  }
}
</script>

<style src="@/style/pc/login.less" scoped lang="less"></style>