import axios from "axios";
import i18next from "i18next";
import { baseUrl } from "../config/env";
import { Loading, Message } from 'element-ui';
import { alertBox, errorInfo } from '@/utils'
import { isEmpty } from "lodash";
import Router from '@/router';
import Store from '@/store';

var loadingInstance = null; // 记录页面中存在的loading
var loadingCount = 0; // 记录当前正在请求的数量
 
function showLoading(data) {
  if (loadingCount === 0) {
    loadingInstance = Loading.service({
      lock: true,
      text: data || i18next.t('common.loading'),
    });
  }
  loadingCount++
};
 
function hideLoading() {
  loadingCount--
  if (loadingInstance && loadingCount === 0) {
    loadingInstance.close()
    loadingInstance = null
  }
};

addRequestInterceptor();
addResponseInterceptor();

export default ({ url, data, method = "get", headers }) => {
  const func = axios[method.toLowerCase()];
  if(method.toLowerCase() === 'get') data = {params: data};
  return func(`${baseUrl}${url}`, data, {
    headers: Object.assign({ "Content-Type": "application/json;charset=UTF-8" }, headers),
    withCredentials: true,
    validateStatus: function (status) {
      return status >= 200 && status < 300;
    }
  });
};

/**
 * 请求拦截器
 */
function addRequestInterceptor() {
  axios.interceptors.request.use(function(config) {
    showLoading(config.loadingText)
    setTimeout(() => {
      hideLoading()
    }, 500)
    return config
  })
}

/**
 * 响应拦截器
 */
function addResponseInterceptor() {
  axios.interceptors.response.use(
    function (response) {
      hideLoading();
      if(response.config.url && response.config.url.includes('/front/download/downLoadFile')) return response.data; //下载接口
      if(response.data.rescode !== 200) {
        if(['A0001', 'A0004'].includes(response.data.msg)) {
          Store.commit('setUserInfo', ''); // 清除vuex 清除cookie 清除localStorage
          Store.commit('setMenus', '');  // 清除权限菜单
        }
        if(!((response.data.rescode && [200, 300].includes(response.data.rescode)) || (response.data.msg && ['A0001', 'A0004'].includes(response.data.msg)))) {
          if(response.data.msg.startWith('B')) {
            errorInfo(`code.${response.data.msg}`, (isEmpty(response.data.data)? '' : response.data.data))
          } else if(response.data.msg.startWith('A')) {
            errorInfo(`code.${response.data.msg}`)
          } else {
            errorInfo(response.data.msg)
          }
        }

      }
      return checkResponseResult(response);
    },
    function (error) {
      hideLoading()
      return Promise.reject(error);
    }
  );
}

/*
 * 检查响应数据
 * @params { object } res - 响应信息
 */
function checkResponseResult(res) {
  if (res.data.rescode === 200) return res.data;  // success回调函数
  return Promise.reject(res.data); // error回调函数
}