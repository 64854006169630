import Router from '@/router';
import { errorInfo } from '@/utils';

// const permission = {//参数1：自定义指令名称，参数2：钩子函数
//     bind: function (el, binding, vnode) {
//       el.addEventListener('click', function (event) {
//         event.preventDefault();
//         if(vnode.context.$store.state.userInfo && !vnode.context.$store.state.menus.some(item => (item.title === binding.value))) 
//         errorInfo('common.note.010');
//         event.preventDefault();
//         event.stopPropagation();
//         return false
//       });
//     }
// }

const login = {
  bind: function (el, binding, vnode) {
    const clickHandler = function (event) {
      event.preventDefault();
      event.stopPropagation(); // 阻止事件冒泡
      if (!vnode.context.$store.state.userInfo) {
        errorInfo('common.note.026');
        setTimeout(() => Router.replace(`/portal/login?redirect=${Router.currentRoute.fullPath}`), 1000);
        return false;
      } else {
        binding.value();
      }
    };
    el.addEventListener('click', clickHandler);
    vnode.context[binding.arg + 'Handler'] = clickHandler
  },
  unbind: function (el, binding, vnode) {
    // 获取事件处理函数
    const clickHandler = vnode.context[binding.arg + 'Handler']
    // 取消事件的监听
    el.removeEventListener('click', clickHandler)
  }
}

export default {
  // permission,
  login
}