<template>
    <footer>
        <div class="middle" style="box-sizing: border-box;">
            <div class="left logo" @click="jumpWebsite">
                <img src="@/assets/images/logo.png" v-if="!$global.isOverSeas" style="width: 100%">
                <img src="../../assets/images/logo_white.jpg" v-else>
            </div>
            <div class="footer-content">
                <div class="footer-tab">
                    <div v-for="(item, index) in footerTabs" :key="index" class="tab-item">
                        <a :href="basePath + item.href">{{item.title}}</a>
                        <div class="line"></div>
                        <div class="footer-child-tab" v-if="item.children && item.children.length">
                            <div v-for="(item1, index1) in item.children" :key="index1" class="child-tab-item" @click="handleClick(item1, index1)">
                                <span>{{item1.title}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="gov flex align-items-center">
                    <span>{{$t('common.footer.gov')}}</span>
                    <a v-if="!$global.isOverSeas" class="record" target="_blank" href="https://www.miit.gov.cn/">&nbsp;&nbsp;&nbsp;&nbsp;{{$t('common.footer.record')}}-7</a>
                </div>
            </div>
            <div class="right">
                <p style="font-size: 16px;">{{$t('common.footer.contact')}}</p>
                <div style="width: 90%; height: 1px; background: rgba(51,51,51);margin: 20px 0 0;"></div>
                <div v-if="!$global.isOverSeas">
                    <p style="color: #666666;">{{$t('common.footer.phoneNum')}}</p>
                    <p style="font-size: 24px; font-weight: 600;">400-677-0876</p>
                </div>
                <div v-else>
                    <p>{{$t('common.footer.email')}}</p>
                    <p style="font-size: 24px; font-weight: 600;">technical@seuic.com</p>
                </div>
                <div class="code" v-if="!$global.isOverSeas">
                    <img src="@/assets/images/erweima.jpg" />
                    <div class="code-text">
                        <div>{{$t('common.footer.code.001')}}</div>
                        <div>{{$t('common.footer.code.002')}}</div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
import { basePath, baseUrl } from '@/config/env.js';
import { alertBox } from '@/utils/index';
import { mapGetters } from 'vuex';
export default {
    data() {
        return {
            footerTabs: [
                {
                    title: this.$t('common.footer.home'),  href: '/', 
                    children: [
                        {title: this.$t('common.footer.home_one'), href: '/notice'},
                    ]
                },
                {
                    title: this.$t('common.footer.solution'),  href: '/solution', 
                    children: [
                        {title: this.$t('common.footer.solution_one'), href: '/solution?tabIndex=1'},
                        {title: this.$t('common.footer.solution_two'), href: '/solution?tabIndex=2'},
                    ]
                },
                {
                    title: this.$t('common.footer.marketplace'),  href: '/marketplace', 
                    children: [
                        {title: this.$t('common.footer.marketplace_one'), href: '/marketplace?tabIndex=1'},
                        {title: this.$t('common.footer.marketplace_two'), href: '/marketplace?tabIndex=2'},
                    ]
                },
                {
                    title: this.$t('common.footer.marketing'),  href: '/marketing', 
                    children: [
                        // {title: this.$t('common.footer.marketing'), href: '/marketing'},
                    ]
                },
                {
                    title: this.$t('common.footer.developer'),  href: '/developer',
                    children: [
                        {title: this.$t('common.footer.developer_one'), href: '/developer?menuIndex=1-1' },
                        {title: this.$t('common.footer.developer_two'), href: '/developer?menuIndex=2-1' },
                        {title: this.$t('common.footer.developer_three'), href: '/articleWendan' },
                    ]
                },
                {
                    title: this.$t('common.footer.support'),  href: '/support', 
                    children: [
                        {title: this.$t('common.footer.support_one'), href: '/support?tabIndex=1'},
                        {title: this.$t('common.footer.support_two'), href: '/support?tabIndex=2'},
                    ]
                }
            ],
            basePath: basePath
        }
    },
    computed: {
        ...mapGetters(['getUserInfo'])
    },
    methods: {
        jumpWebsite() {
            if(this.$global.isOverSeas) {
                window.open('https://en.seuic.com/', '_blank');
            } else {
                window.open('https://www.seuic.com/', '_blank');
            }
        },
        handleClick(item, index) {
            this.$router.push({path: item.href});
            // if(item.href === '/articleWendan') {
            //     this.$store.commit('setActiveIndex', item.href);
            // }
        }
    }
}
</script>

<style lang="less" scoped>
// pc端
@media screen and (min-device-width: @xs) {
    @import '@/style/pc/footer.less';
}
// 移动端
@media screen and (max-device-width: @xs){
    @import '@/style/mobile/footer.less';
}
</style>