import { render, staticRenderFns } from "./forgetPassword.vue?vue&type=template&id=41b7cc92&scoped=true&"
import script from "./forgetPassword.vue?vue&type=script&lang=js&"
export * from "./forgetPassword.vue?vue&type=script&lang=js&"
import style0 from "@/style/pc/login.less?vue&type=style&index=0&id=41b7cc92&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "41b7cc92",
  null
  
)

export default component.exports