<template>
  <div class="manage container">
    <header-view/>
    <div class="main">
      <router-view/>
    </div>
    <footer-view/>

    <div class="rightbox" id="box8">
        <!--<ul class="rightbox-1">
            &lt;!&ndash;<li>
                <img src="@/assets/images/appmall/zxzx.png" width="32px">
                <p>在线咨询</p>
            </li>&ndash;&gt;
            <el-tooltip effect="light" content="400-677-0876" placement="left" width="20px">
                <li>
                    <img src="@/assets/images/appmall/kfrx.png" width="32px" style="width: 32px;">
                    <p><a style="cursor: pointer">客服热线</a></p>
                </li>
            </el-tooltip>
            <li style="border: none" @click="jumpOrder()">
                <img src="@/assets/images/appmall/tjgd.png" width="27px"  style="width: 27px;">
                <p><a style="cursor: pointer">提交工单</a></p>
            </li>
        </ul>-->

        <a href="javascript:void(0)" @click="toTop"><img src="@/assets/images/appmall/toplable.png" style="width: 42px;"></a>
    </div>
  </div>
</template>

<script>
import { getMenus } from '@/api/common';
import { getLoginUser } from '@/api/user';
import HeaderView from '@/components/content/header.vue';
import FooterView from '@/components/content/footer.vue';
export default {
  components: {HeaderView,FooterView},
  created() {
    // this.getLoginUser();
  },
  methods: {
    // 获取动态菜单
    getMenus() {
      getMenus({platformId:20,type:1}).then(res => {
          if (res.rescode == 200){
            this.$store.commit('setMenus', res.data);
          }
      }, error => {})
    },
    // 获取用户信息
    getLoginUser() {
      getLoginUser().then(res => {
        if (res.rescode === 200) {
          this.$store.commit('setUserInfo', res.data);
          this.getMenus();
        }
      }, error => {})
    },
    // 跳转提交工单界面
    // jumpOrder() {
    //   window.location.href = basePath + "/jumpOrder?tabIndex=" + "2";
    // },
    toTop() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      })
    }
  }
}
</script>

<style src="@/style/pc/manage.less" lang="less" scoped></style>
<style lang="less" scoped>
// 移动端
@media screen and (max-device-width: @xs) {
  @import '@/style/mobile/manage.less';
}
</style>