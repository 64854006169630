export default {
    A0001: '登录后查看',
    A0002: '已存在',
    A0003: '用户名已存在',
    A0004: '登陆超时',
    A0005: '用户名或手机号已存在',
    A0006: '该手机号已注册用户，请勿重复注册',
    A0007: '账号已注册等待审核',
    A0008: '验证码错误',
    A0009: '该用户已经实名认证了',
    A0010: '该用户已经在实名认证中',
    A0011: '同一个身份证号只能认证一个账号',
    A0012: '原登录密码不正确',
    A0013: '该企业已认证过，不能再认证！',
    A0014: '该企业在认证中，不能再认证！',
    A0015: '修改失败',
    A0016: '找回密码失败!',
    A0017: '很抱歉！您没有访问该资源的权限。',
    A0018: '根据公司名称查询到多条公司数据，请确认客户信息中是否存在冗余数据，并清理。',
    A0019: '账号关联客户信息失败，请重试，或联系运维人员。',
    A0020: '平台系统下存在菜单，请先删除菜单后删除。',
    A0021: '上级部门不能是自己或子级',
    A0022: '该部门存在子部门，无法操作',
    A0023: '该部门已被使用，无法操作',
    A0024: '请先启用上级部门',
    A0025: '启动任务失败',
    A0026: '暂停任务失败',
    A0027: '用户角色获取失败',
    A0028: '该菜单存在子节点，无法删除',
    A0029: '请先禁用子菜单',
    A0030: '请按列表顺序从最高的版本开始逐级删除，删除最高版本后，应用版本将回退至上一版本。',
    A0031: '请先禁用子分类',
    A0032: '该分类存在子分类，请先删除子分类！',
    A0033: '客户已被账号关联，请前删除关联账号。',
    A0034: '发送短信验证码失败',
    A0035: '该账号未审核，请等待审核',
    A0036: '删除账号中存在子账号，请先删除子账号。',
    A0037: '有已审核通过的版本未发布',
    A0038: '此账号暂无权限查看',
    A0039: '必须先通过测试',
    A0040: '最高版本已经审核，不用审核',
    A0041: '提交发布失败，请刷新重试或联系维护人员',
    A0042: '应用名称已经存在',
    A0043: '部门名称已经存在',
    A0044: '定时任务已存在',
    A0045: '权限标识已存在',
    A0046: '唯一键已存在',
    A0047: '手机号已存在',
    A0048: '工号已存在',
    A0049: '账号不存在',
    A0050: '密码错误',
    A0051: '密码错误次数过多，请5分钟后再试',
    A0052: '当前IP无法登录',
    A0053: '账号已被禁用',
    A0054: '账号已过期',
    A0055: '短信验证码已过期',
    A0056: '新增失败',
    A0057: '发送失败',
    A0058: '上传的文件为空',
    A0059: '内部错误',
    A0060: '邮箱已存在',
    A0061: '用户名或邮箱已存在',
    A0062: '该邮箱已注册用户，请勿重复注册',
    A0063: '邮件发送失败',
    A0064: '上传文件失败',
    A0065: '名称已存在',
    A0066: '资料名称不可重复',
    A0067: 'MDM同步失败',
    A0068: '下载失败',
    A0069: '导出失败',
    A0070: 'apiKey错误',
    A0071: '加密信息有误',
    A0072: '账号不可用',
    A0073: '请求链接已过期',
    A0074: '手机号不存在',
    A0075: '验证码获取次数过于频繁，请1小时再试',
    A0076: '此邮箱没有被绑定到该账户',
    A0077: '无关联文档或不可见',
    A0078: '资源不存在或不可见',
    A0079: '操作过于频繁，请稍后再试',

    B0001: '缺少必要参数',
    B0002: '角色被引用，无法删除',
    B0003: '您的ip不在白名单内',
    B0004: '参数不合法',
    B0005: '请求出错',
    B0006: '上传文件大小超过限制',
    B0007: '图片格式有误',
}