import { successInfo } from "./index";

String.prototype.startWith = function (s) {
  if (s == null || s == "" || this.length == 0 || s.length > this.length)
      return false;
  if (this.substr(0, s.length) == s)
      return true;
  else
      return false;
  return true;
}

String.prototype.endWith = function (s) {
  if (s == null || s == "" || this.length == 0 || s.length > this.length)
      return false;
  if (this.substring(this.length - s.length) == s)
      return true;
  else
      return false;
  return true;
}

Array.prototype.indexOf = function (val) {
  for (var i = 0; i < this.length; i++) {
      if (this[i] == val) return i;
  }
  return -1;
}

Array.prototype.remove = function (val) {
  var index = this.indexOf(val);
  if (index > -1) {
      this.splice(index, 1);
  }
}

Date.prototype.format = function () {
  var year = int2Str(this.getFullYear(), 4);
  var month = int2Str(this.getMonth() + 1, 2);
  var day = int2Str(this.getDate(), 2);
  return year + "-" + month + "-" + day;
}

function int2Str(num, len) {
  var prev = "";
  for (var i = 0; i < len; i++) {
      prev += "0";
  }
  return (prev + num).slice(-len);
}

//根据时间戳获取年月日时分秒
function formatDateTime(inputTime) {
  var date = new Date(inputTime);
  var y = date.getFullYear();
  var m = date.getMonth() + 1;
  m = m < 10 ? ('0' + m) : m;
  var d = date.getDate();
  d = d < 10 ? ('0' + d) : d;
  var h = date.getHours();
  h = h < 10 ? ('0' + h) : h;
  var minute = date.getMinutes();
  var second = date.getSeconds();
  minute = minute < 10 ? ('0' + minute) : minute;
  second = second < 10 ? ('0' + second) : second;
  return y + '-' + m + '-' + d + ' ' + h + ':' + minute + ':' + second;
}

//根据时间戳获取年月日
function formatDate(inputTime) {
  var date = new Date(inputTime);
  var y = date.getFullYear();
  var m = date.getMonth() + 1;
  m = m < 10 ? ('0' + m) : m;
  var d = date.getDate();
  d = d < 10 ? ('0' + d) : d;
  return y + '-' + m + '-' + d;
}

//根据时间戳获取时分秒
function formatTime(inputTime) {
  var date = new Date(inputTime);
  var h = date.getHours();
  h = h < 10 ? ('0' + h) : h;
  var minute = date.getMinutes();
  var second = date.getSeconds();
  minute = minute < 10 ? ('0' + minute) : minute;
  second = second < 10 ? ('0' + second) : second;
  return h + ':' + minute + ':' + second;
}

//根据时间戳获取月份
function formatMonths(inputTime) {
  var date = new Date(inputTime);
  var m = date.getMonth() + 1;
  m = m < 10 ? ('0' + m) : m;
  return m;
}

//根据时间戳获取年月
function formatMonth(inputTime) {
  var date = new Date(inputTime);
  var y = date.getFullYear();
  var m = date.getMonth() + 1;
  m = m < 10 ? ('0' + m) : m;
  return y + '-' + m;
}

//根据时间戳获取年
function formatYear(inputTime) {
  var date = new Date(inputTime);
  var y = date.getFullYear();
  return y;
}

function copy(text) {
  if (document.execCommand('Copy')) {
      //创建input
      var inputZ = document.createElement('input');
      //添加Id,用于后续操作
      inputZ.setAttribute('id', 'inputCopy');
      //获取当前链接
      inputZ.value = getHtmlContent(text);
      //创建的input添加到body
      document.body.appendChild(inputZ);
      //选中input中的值
      document.getElementById('inputCopy').select();
      //把值复制下来
      document.execCommand('Copy')
      //删除添加的input
      document.body.removeChild(inputZ);
      successInfo('common.copy_success');
  }
}

function isEmpty(val) {
  var result = (val == null || val == undefined || val == "");
  return result;
}

function getQueryString(name) {
  var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
  var r = window.location.search.substr(1).match(reg);
  if (r != null) return unescape(r[2]);
  return null;
}

function getHtmlContent(content, len) {
  if (typeof (content) != 'undefined' && content != '') {
      var dd = content.replace(/<\/?.+?>/g, "");
      var dds = dd.replace(/ /g, "");
      dds = dds.replace(/&nbsp;/gi, "");
      dds = dds.replace(/&nbsp/gi, "");
      dds = dds.replace(/&amp;/gi, "&");
      dds = dds.replace(" ", "");
      if (len != undefined && dds.length > len) {
          dds = dds.substring(0, len);
      }
      return dds;
  }
  return "";
}

//获取页面顶部被卷起来的高度
function scrollTop() {
  return Math.max(
      //chrome
      document.body.scrollTop,
      //firefox/IE
      document.documentElement.scrollTop);
}

//获取页面文档的总高度
function documentHeight() {
  //现代浏览器（IE9+和其他浏览器）和IE8的document.body.scrollHeight和document.documentElement.scrollHeight都可以
  return Math.max(document.body.scrollHeight, document.documentElement.scrollHeight);
}

function windowHeight() {
  return (document.compatMode == "CSS1Compat") ?
      document.documentElement.clientHeight :
      document.body.clientHeight;
}

function windowWidth() {
  return (document.compatMode == "CSS1Compat") ?
      document.documentElement.clientWidth :
      document.body.clientWidth;
}

// 小数两位,个位时补0
function smallInt(i) {
  var num = Math.round(i * 100) % 100;
  if (num == 0) {
      return "00";
  } else if (num < 10) {
      return "0" + num;
  }
  return num + "";
}

function getUrl() {
  var href = window.location.href;
  var arr = href.split('#');
  var link = arr[0];
  return link;
}

function deepClone(s) {
  var i;
  var p = isClass(s);
  if (p === "Object") {
      i = {}
  } else {
      if (p === "Array") {
          i = []
      } else {
          return s
      }
  }
  for (var r in s) {
      if (!s.hasOwnProperty(r)) {
          continue
      }
      var t = s[r], q = isClass(t);
      if (q === "Object") {
          i[r] = arguments.callee(t)
      } else {
          if (q === "Array") {
              i[r] = arguments.callee(t)
          } else {
              i[r] = s[r]
          }
      }
  }
  return i
}

function isClass(i) {
  if (i === null) {
      return "Null"
  }
  if (i === undefined) {
      return "Undefined"
  }
  return Object.prototype.toString.call(i).slice(8, -1)
}

//递归树结构数据查找所有父级(省市区)
function findParentNode(ids, acceptUnitNodes) {
  var parentNodes = [];//所有父节点
  var forfun = function (id, nodes) {
      for (var i = 0; i < nodes.length; i++) {
          var currentNode = nodes[i];
          if (currentNode.value == id) {
              return currentNode.value;
          } else if (currentNode.children) {
              var validNodeId = forfun(id, currentNode.children);
              if (validNodeId && parentNodes.indexOf(validNodeId) < 0) {
                  parentNodes.push(validNodeId)
              }
              if (validNodeId) {
                  return currentNode.value;
              }
          }
      }
  }

  $.each(ids, function (i, item) {
      var validNodeId = forfun(item, acceptUnitNodes);
      if (validNodeId && parentNodes.indexOf(validNodeId) < 0) {
          parentNodes.push(validNodeId);
      }
  })
  return parentNodes;
}

//树形结构根据编号删除（包括子级-省市区）
function filter(data, id) {
  var newData = data.filter(x => x.value !== id)
  newData.forEach(x => x.children && (x.children = filter(x.children, id)))
  return newData
}

//list数据转树状层级JSON，递归生成树状层级JSON(省市区)
function getJsonTree(data, parentId) {
  var itemArr = [];
  for (var i = 0; i < data.length; i++) {
      var node = data[i];
      if (node.parentId == parentId) {
          var newNode = {
              value: node.value,
              name: node.name,
              parentId: node.parentId,
              disabled: false,
              children: getJsonTree(data, node.value)
          };
          itemArr.push(newNode);
      }
  }
  if (itemArr.length > 0) {
      return itemArr;
  } else {
      return null;
  }
}

//根据子级查询父级(故障部件的机型关联)
function getParentEle(list, id) {
  for (let i = 0; i < list.length; i++) {
      if (list[i].id === id) {
          return [list[i]]
      }
      if (list[i].children) {
          let node = getParentEle(list[i].children, id);
          if (node !== undefined) {
              return node.concat(list[i])
          }
      }
  }
}

//根据父级查询低四级子级(故障部件的机型关联)
function getChild(nodes, item, arr) {
  for (let el of nodes) {
      if (el.id === item) {
          if(el.type === 4){
              arr.push(el.id);
          }
          if (el.children) {
              this.childNodesDeep(el.children, arr);
          }
      } else if (el.children) {
          this.getChild(el.children, item, arr);
      }
  }
  return arr;
}

function childNodesDeep(nodes, arr) {
  if (nodes)
      nodes.forEach((ele) => {
          if(ele.type === 4){
              arr.push(ele.id);
          }
          if (ele.children) {
              this.childNodesDeep(ele.children, arr);
          }
      });
}

//数组去重
function unique(arr) {
  var res = [];
  var obj = {};
  for (let i = 0; i < arr.length; i++) {
      if (!obj[arr[i].id]) {
          obj[arr[i].id] = 1;
          res.push(arr[i]);
      }
  }
  return res;
}

// 扁平化+去重
function flattenAndUnique(arr) {
  function flatten(arr) {
      return arr.reduce((a, b) => [].concat(Array.isArray(a) && a ? flatten(a) : a, Array.isArray(b) && b ? flatten(b) : b), [])
  };
  return Array.from(new Set(flatten(arr)))
}

// 数组转为树形结构
function treeDataTranslate (data, id = 'id', pid = 'parentId') {
  if(!data || data.length < 0) return []
  var res = [], temp = {};
  for (var i = 0; i < data.length; i++) {
      temp[data[i][id]] = data[i]
  }
  for (var k = 0; k < data.length; k++) {
      if (temp[data[k][pid]] && data[k][id] !== data[k][pid]) {
          if (!temp[data[k][pid]]['children']) {
              temp[data[k][pid]]['children'] = []
          }
          if (!temp[data[k][pid]]['_level']) {
              temp[data[k][pid]]['_level'] = 1
          }
          data[k]['_level'] = temp[data[k][pid]]._level + 1
          temp[data[k][pid]]['children'].push(data[k])
      } else {
          res.push(data[k])
      }
  }
  return res
}

// 判断是否为JSON
function isJSON(str) {
  if (typeof str == 'string') {
      try {
          var obj=JSON.parse(str);
          if(typeof obj == 'object' && obj ){
              return true;
          }else{
              return false;
          }

      } catch(e) {
          console.log('error：'+str+'!!!'+e);
          return false;
      }
  }
  console.log('It is not a string!')
}

// 判断设备是否为移动端的方法
function isMobile() {
    return /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i.test(
      navigator.userAgent
    );
};

//
function changeCollectionsKey(arr, labels) {
    return arr.map(item => {
        let obj = {};
        Object.entries(item).forEach(([k, v]) => {
          obj[labels[k] || k] = v
        })
        return obj
    })
}

// a标签下载 可以直接下载txt、png、pdf、exe、xlsx等类型文件
function downloadEvt(url, fileName) {
    const el = document.createElement('a');
    el.style.display = 'none';
    el.setAttribute('target', '_blank');
    fileName && el.setAttribute('download', fileName);
    el.href = url;
    document.body.appendChild(el);
    el.click(); 
    document.body.removeChild(el);
};

// 请求返回blob数据
function downloadFile(response, fileName) {
	const headers = response.headers;
	const contentType = headers['content-type'];
	const blob = new Blob([response.data], { type: contentType });
	const temp = response.headers['content-disposition'];
	let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
	let matches = filenameRegex.exec(temp);
	let fileType = '';
	if (matches != null && matches[1]) {
		fileType = matches['input'].substring(matches['input'].lastIndexOf('.'));
	}
	var downloadElement = document.createElement('a');
	var href = window.URL.createObjectURL(blob); //创建下载的链接
	downloadElement.href = href;
	downloadElement.download = fileName + fileType; //下载后文件名
	document.body.appendChild(downloadElement);
	downloadElement.click(); //点击下载
	document.body.removeChild(downloadElement); //下载完成移除元素
	window.URL.revokeObjectURL(href); //释放掉blob对象
}

export {
    getQueryString,
    isJSON,
    isMobile,
    changeCollectionsKey,
    downloadEvt,
}