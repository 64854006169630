export default {
  menus: {
    '001': '应用管理',
    '002': '开发能力',
    '003': '技术文档',
    '004': '应用上架',
    '005': 'API接口',
    '006': 'SDK',
    '007': '开发资源', // '测试包',
    '008': '开发文档',
  },
  devApi: {
    tabs: {
      '001': '全部',
    },
    breadcrumb: {
      '001': '首页',
      '002': '开发能力',
      '003': 'API接口',
    },
    label: {
      '001': '免费',
      '002': '收费',
    },
    operation: {
      '001': 'API文档',
      '002': '购买流量包',
    }
  },
  devApp: {
    breadcrumb: {
      '001': '首页',
      '002': '应用管理',
      '003': '应用上架',
    },
    title: '应用上架',
    addApp: '新增应用',
    table: {
      '001': '应用ICON',
      '002': '应用名称',
      '003': '版本号',
      '004': '评审状态',
      '005': '应用状态',
      '006': '提交时间',
      '007': '审核时间',
      '008': '操作',
    },
    stateOpts: {
      '001': '待提交',
      '002': '已审核',
      '003': '待审核',
      '004': '更新待审核',
      '005': '更新审核驳回',
      '006': '审核不通过',
      '007': '驳回原因：',
    },
    statusOpts: {
      '001': '已上架',
      '002': '待上架',
    },
    operation: {
      edit: '编辑',
      delete: '删除',
      update: '版本更新',
      find: '查看',
      release: '上架',
      unRelease: '下架',
    },
    add: {
      title: '新增应用',
    },
    edit: {
      title: '修改应用',
    },
    find: {
      title: '查看应用',
    },
    note: {
      '001': '是否删除该应用？',
      '002': '是否下架该应用？',
      '003': '是否上架该应用？',
    },
  },
  devSdk: {
    tabs: {
      '001': '通用',
      '002': '机型专用',
    },
    breadcrumb: {
      '001': '首页',
      '002': '开发能力',
      '003': 'SDK',
      '004': 'SDK文件下载',
    },
    table: {
      '001': '文件名称',
      '002': '格式',
      '003': '大小(MB)',
      '004': '下载次数',
      '005': '最后更新',
      '006': '操作',
    },
    operation: {
      '001': 'API文档',
      '002': 'SDK下载',
      '003': '下载SDK',
      '004': '开发文档',
    }
  },
  testPkg: {
    breadcrumb: {
      '001': '首页',
      '002': '开发能力',
      '003': '测试包',
    },
    title: '系统',
    table: {
      '001': '文件名称',
      '002': '格式',
      '003': '大小(MB)',
      '004': '下载次数',
      '005': '最后更新',
      '006': '操作',
    },
    operation: {
      '001': '下载',
    }
  },
  sdkPkg: {
    breadcrumb: {
      '001': '首页',
      '002': '开发能力',
      '003': 'SDK',
      '004': 'SDK文件下载',
    },
    table: {
      '001': '文件名称',
      '002': '格式',
      '003': '大小(MB)',
      '004': '下载次数',
      '005': '最后更新',
      '006': '操作',
    },
    operation: {
      '001': '下载'
    }
  },
  addApp: {
    table: {
      '001': '应用名称：',
      '002': '版本号：',
      '003': '版本名称：',
      '004': '应用包名：',
      '005': '应用分类：',
      '006': 'ICON：',
      '007': '安装包：',
      '008': '应用简介：',
      '009': '测试账号：',
      '010': '介绍图片/视频：',
      '011': '更新内容',
    },
    placeholder: {
      '001': '请输入应用名称',
      '002': '请输入版本号',
      '003': '请输入版本名称',
      '004': '请输入应用包名',
      '005': '请选择应用分类',
      '006': '分辨率建议 512 x 512px, 支持png图片格式, 大小2M内',
      '007': '点击或拖动文件上传',
      '008': '只能上传apk文件，且文件大小小于150M',
      '009': '请输入应用简介',
      '010': '请输入内容',
      '011': '请输入更新内容',
    },
    rule: {
      '001': "安装包不能为空！",
      '002': "应用名称不能为空！",
      '003': "版本号不能为空！",
      '004': "版本名称不能为空！",
      '005': "应用包名不能为空！",
      '006': "应用分类不能为空！",
      '007': "ICON不能为空！",
      '008': "应用描述不能为空！",
      '009': "介绍图片/视频不能为空！",
    },
    operation: {
      '001': '取消',
      '002': '保存暂不提交',
      '003': '提交审核',
    }
  },
  editApp: {
    table: {
      '001': '应用名称：',
      '002': '版本号：',
      '003': '版本名称：',
      '004': '应用包名：',
      '005': '应用分类：',
      '006': 'ICON：',
      '007': '安装包：',
      '008': '应用简介：',
      '009': '测试账号：',
      '010': '介绍图片/视频：',
    },
    note: {
      '001': '无安装包',
    },
  },
  verApp: {
    breadcrumb: {
      '001': '首页',
      '002': '应用管理',
      '003': '应用上架',
      '004': '版本更新',
    },
    addBtn: '新增版本',
    table: {
      '001': '版本号',
      '002': '版本名称',
      '003': '评审状态',
      '004': '下载次数',
      '005': '提交时间',
      '006': '操作',
    },
    stateOpts: {
      '001': '待提交',
      '002': '已审核',
      '003': '待审核',
      '004': '已发布',
      '005': '更新审核驳回',
      '006': '驳回',
      '007': '驳回原因：',
    },
    operation: {
      'edit': '编辑',
      'release': '发布',
      'submit': '提交审核',
    },
    note: {
      '001': '是否发布该版本?',
    }
  },
  devHome: {
    title: '需实名认证成为开发者，才可使用相关服务',
    btn: '前往认证',
     operation: {
      '001': '填写资料',
      '002': '提交审核',
      '003': '待审核',
      '004': '审核通过',
     }
  },
  articleDetail: {
    '001': '更新时间：',
    '002': '浏览量：',
  },
  mobile: {
    '001': '开发文档',
    '002': '驱动测试包',
    '003': '常见问题',
    list: {
      '001': '文章名称',
      '002': '作者',
      '003': '浏览量',
      '004': '创建时间',
      '005': '格式',
      '006': '大小(MB)',
      '007': '下载量',
      '008': '更新时间',
    }
  }
} 