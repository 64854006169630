<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {

}
</script>
<style scoped lang="less">
#app {
  height: 100%;
  // font-family: Avenir, Helvetica, Arial, sans-serif;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #2D3748;
}
</style>
