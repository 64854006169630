<template>
  <el-dialog
      title=""
      :visible.sync="centerdialogvisible"
      :width="!$global.isMobile? '30%' : '90%'"
      :show-close="false"
      :close-on-click-modal="false"
      top="30vh"
      center>
    <div class="complete">
      <img :src="complete.pic" width="63px">
      <span class="titles">{{complete.title}}</span>
      <span class="subtitles">{{complete.subtitle}}</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="center" class="buttons">{{complete.button}}</el-button>
      </span>
    </div>

  </el-dialog>
</template>

<script>
export default {
  props: {
    complete:Object,
    centerdialogvisible:Boolean
  },
  data () {
    return {
    }
  },
  methods: {
    onSubmit() {
      console.log('submit!');
    },
    center(){
      this.$parent.centerdialogvisible = false;
      this.$emit("buttons");
    }
  },
}
</script>

<style scoped>
.complete{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.titles{
  font-size: 19px;
  margin: 10px;
}
.subtitles{
  font-size: 14px;
  color: rgba(0,0,0,0.4500);
}
.buttons{
  background: #0075C1;
  width: 320px;
  color: #fff;
  margin-top: 40px;
}
</style>