export default {
  banner: {
    title: '解决方案中心',
    subTitle: '集聚行业解决方案，为终端产品进行全方位赋能',
  },
  solutionone: {
    title: '解决方案查询',
    operation: {
      '001': '查看详情',
      '002': '分享方案',
    }
  },
  equipmentPlan: {
    title: '设备适用方案查询',
  },
  search: {
    '001': '产品一级目录',
    '002': '产品二级目录',
  },
  solutionDetail: {
    breadcrumb: {
      '001': '解决方案',
      '002': '推荐方案',
      '003': '电商行业',
      '004': '物流行业',
      '005': '零售行业',
      '006': '医疗行业',
      '007': '生产制造行业',
      '008': '公共事业',
      '009': '其他行业',
    },
    detail: {
      '001': '方案价格：',
      '002': '元',
      '003': '联系售前咨询',
      '004': '服务：',
      '005': '方案获取对接',
      '006': '由东集提供服务',
    },
    case: {
      title: '方案简介',
    },
    equipment: {
      title: '适用设备',
    },
    caseLoad: {
      title: '资料下载',
      table: {
        '001': '素材名称',
        '002': '格式',
        '003': '大小(MB)',
        '004': '下载次数',
        '005': '最后更新',
        '006': '操作',
      }
    }
  },
  checkingData: {
    breadcrumb: {
      '001': '适用设备',
      '002': '产品资料',
    },
    search: {
      '001': '产品线',
      '002': '产品类型',
    },
    table: {
      '001': '素材名称',
      '002': '格式',
      '003': '大小(MB)',
      '004': '下载次数',
      '005': '最后更新',
      '006': '操作',
    }
  },
  mobile: {
    '001': '方案获取',
  }
}