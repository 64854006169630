export default {
  lang: '简体中文',
  zh: '简体中文',
  en: 'English',
  intl: 'International',
  china: '中国站',
  header: {
    title: 'IoT设备数字化管理与应用平台',
    subTitle: '东集官网',
    home: '首页',
    solution: '解决方案',
    marketplace: '应用市场',
    marketing: '营销工具',
    developer: '开发者',
    support: '服务支持',
    msg: '消息',
    login: '请先登录',
    logout: '退出登录',
    myInfo: '我的信息',
    model_selection: '选型工具',
    login_btn: '登录',
    register_btn: '注册',
    favorite: '我的收藏',
  },
  search: '搜索',
  searchPlaceholder: '请输入内容搜索并分别点击切换分类标签进行查找',
  searchRes: {
    '001': '包含“',
    '002': '”的查询结果为',
    '003': '条',
  },
  list: '资料列表',
  back: '返回',
  empty: '暂无数据',
  add: '新增',
  edit: '编辑',
  delete: '删除',
  update: '更新',
  find: '查询',
  detail: '详情',
  buy: '立即购买',
  download: '下载',
  preview: '预览',
  loading: '加载中',
  failedLoad: '加载失败',
  findInfo: '查看资料',
  save: '保存',
  cancel: '取消',
  find: '查看',
  open: '打开',
  close: '关闭',
  send: '发送',
  submit: '提交',
  success: '成功',
  fail: '失败',
  select: '请选择',
  refresh_success: '验证码刷新成功',
  copy_success: '复制成功',
  copy_fail: '复制失败',
  copy: '复制',
  submit_success: '提交成功',
  check_detail: '查看详情',
  operate_success: '操作成功',
  add_success: '添加成功',
  edit_success: '修改成功',
  delete_success: '删除成功',
  save_success: '保存成功',
  clear_success: '清除成功',
  operate_fail: '操作失败',
  operate_warn: '操作警告',
  system_warn: '系统提示',
  ok_text: '确定',
  cancel_text: '取消',
  confirm_continue_text: '你确定要继续吗?',
  confirm_delete_text: '你确定要删除吗?',
  cancel_operate_text: '已取消操作',
  saveAndNoSubmit: '保存暂不提交',
  submitCheck: '提交审核',
  direct_download: '直接下载',
  direct_preview: '直接预览',
  share: '分享',
  logout: {
    '001': '请您再次确认是否退出登录',
    '002': '退出登录失败，请稍后再试...',
  },
  footer: {
    home: '首页',
    home_one: '公告',
    solution: '解决方案',
    solution_one: '解决方案查询',
    solution_two: '设备适用方案查询',
    marketplace: '应用市场',
    marketplace_one: '生态软件',
    marketplace_two: '适配软件查询',
    marketing: '营销工具',
    developer: '开发者',
    developer_one: '应用管理',
    developer_two: '开发能力',
    developer_three: '技术文档',
    support: '服务支持',
    support_one: '常见问题',
    support_two: '服务工单',
    support_three: '选型工具',
    gov: '版权所有©2002-2022 东集技术股份有限公司 保留一切权利',
    record: '苏ICP备11034044号-7',
    phoneNum: '服务热线: ',
    email: '联系邮箱: ',
    modelSelect: '选型工具',
    code: {
      '001': '扫码关注',
      '002': '微信公众号',
    },
    contact: '联系我们',
  },
  note: {
    '001': '注册成功',
    '002': '网络请求失败',
    '003': '验证码获取成功，请注意查收',
    '004': '资料保存成功',
    '005': '密码修改成功',
    '006': '退出登录失败，请稍后再试..',
    '007': '保存成功，已提交审核！',
    '008': '查看详情失败，用户未登录',
    '009': '很抱歉！您没有访问该资源的权限。',
    '010': '没有权限，请联系管理人员',
    '011': '没有关联的文章内容',
    '012': "暂无权限",
    '013': '没有对应的文档',
    '014': '只能上传的apk文件',
    '015': '上传的文件大小不能超过 150MB!',
    '016': '安装包不能为空！',
    '017': '版本号不能为空！',
    '018': '版本名称不能为空！',
    '019': '更新内容不能为空！',
    '020': '上传的图片只能是 JPG 格式或者 PNG 格式!',
    '021': '上传的图片大小不能超过 2MB!',
    '022': '上传的图片只能是 JPG 格式或者 PNG 格式 或者视频格式!',
    '023': '上传的图片或视频大小不能超过 100MB!',
    '024': '暂不支持预览该格式文件',
    '025': '登录凭证到期,请重新登录',
    '026': '操作失败, 用户未登录',
    '027': '提交工单失败，用户未登录',
    '028': '下载文件失败，用户未登录',
    '029': '预览文件失败，用户未登录',
    '030': '上传文件失败，用户未登录',
    '031': '请联系东集销售',
    '032': '上传的图片只能是 JPG、PNG、JPEG、GIF 格式 或者视频格式!',
    '033': '该文件过大，建议下载预览',
    '034': '跳转失败，请先登录',
    '035': '此页面没有可预览的图片',
  },
  loading: '加载中...',
  notice: '提示',
  loadMore: '加载更多',
  new: '新',
  prevPage: '上一页',
  nextPage: '下一页',
  shareDialog: {
    title: '分享',
    form: {
      '001': '是否需要登录：',
      '002': '需要登录IoT平台',
      '003': '(跳转至登录/注册页面，登录账号后跳转至指定页面)',
      '004': '无需登录',
      '005': '(直接进入指定页面查看下载，有效期7天)',
    },
    cancel: '取消分享',
    ok: '复制地址分享',
  },
  get_link: '获取分享链接',
  share_link: '点击复制获取分享链接',
  favorite: '收藏',
  favorited: '已收藏',
  cancel_favorite: '取消收藏',
  cancel_favorite_success: '取消收藏成功',
  favorite_success: '收藏成功',
  top: '置顶',
  cancel_top: '取消置顶',
  top_success: '置顶成功',
  cancel_top_success: '取消置顶成功',
  operation: '操作',
  params_error: '参数有误',
  batch_preview: '批量预览',
  apps_list: '应用总览',
  sdk_list: 'SDK清单',
  test_package_list: '开发资源清单',
};
