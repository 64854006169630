let version = '1.2.9'; // 前端版本
let path = ''; // 接口地址基础路径
let baseUrl = ''; // 接口地址
let basePath = ''; // 基础路径
let downloadUrl = ''; // 下载基础地址
let zh_website = '';  // 中文版环境
let en_website = ''; // 英文版环境
let baseUrl_web = ''; // 完整前端路径

if(process.env.NODE_ENV === 'production') { // 生产环境
    baseUrl = window.location.origin + path;
    basePath = '/seuiot';
    downloadUrl = '/seuiot';
    zh_website = window.location.origin.includes('iot.seuic.com') ? 'https://iot.seuic.com/seuiot' : 'http://192.168.41.151:802/seuiot';
    en_website = window.location.origin.includes('iot.seuic.com') ? 'https://cdn.iot.seuic.com/seuiot' : 'http://192.168.41.151:803/seuiot';
    baseUrl_web = window.location.origin + '/seuiot'; //
} else { // 开发环境
    baseUrl = process.env.VUE_APP_API_BASE_URL;
    basePath = '/seuiot';
    downloadUrl = '/seuiot';
    zh_website = 'http://192.168.41.151:802/seuiot';
    en_website = 'http://192.168.41.151:803/seuiot';
    baseUrl_web = 'http://localhost:8888/seuiot';
}

export {
  baseUrl,
  basePath,
  downloadUrl,
  zh_website,
  en_website,
  baseUrl_web
}