export default {
  uploadNote: 'If you want to upload relevant software to the Seuic App Market, submit the relevant materials. We will contact you, after review we can distribute your application.',
  upload: 'Upload it now',
  submitNote: 'If you have relevant software to adapt for use on Seuic devices, please contact us and provide the device model, software and developer name. After feasibility evaluation, we will contact you to feedback the evaluation result and the required period of adaptation.',
  submit: 'apply for software adaptation',
  banner: {
    title: 'App Market',
    subTitle1: 'Rich ecological software to enable all aspects of end products',
    subTitle2: 'More apps please download in the App Store of Seuic Device',
  },
  ecology: {
    title: 'Ecological Software',
    label: 'Charge',
    operation: {
      '001': 'Download',
      '002': 'Detail',
    }
  },
  equipment: {
    title: "Adaptation software query",
    tabs: {
      '001': 'Applicable device',
    }
  },
  appMall: {
    breadcrumb: {
      '001': 'App Market',
    },
    detail: {
      '001': 'Charge type:',
      '002': 'Free',
      '003': 'Charge',
      '004': 'Software type: Recommended application',
      '005': 'Software type: Terminal tool software',
      '006': 'Software type: ISV Eco Application',
      '007': 'Version name:',
      '008': 'Apk Name:',
      // '008': 'Application package name:',
      '009': 'Consult now',
      '010': 'Install package download',
    },
    appDetail: {
      title: 'Application Detail',
    },
    contact: {
      '001': 'Please contact us',
      '002': 'Contact person',
      '003': 'Manager Wang',
      '004': 'Contact information',
      '005': '+86 18362969617',
      '006': 'Contact email',
      '007': 'wangchunrong@seuic.com',
      '008': 'Close',
    },
    appDownload: {
      title: 'Data Download',
    }
  }
}