import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from "./i18n"

// 样式引入
import './style/index.less';

// 插件
import './plugins/element-ui';

// element-ui
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI, {
  i18n: function(path, options) {
    return i18n.t(path, options);
  }
});

// lodash
import _ from 'lodash'
Vue.prototype._ = _

// VueCookie
import VueCookie from 'vue-cookie';
Vue.use(VueCookie);

// video.js
import Video from 'video.js'
import 'video.js/dist/video-js.css'
Vue.prototype.$video = Video //引入Video播放器

// vant 移动端
import Vant from 'vant';
import 'vant/lib/index.less';
Vue.use(Vant);

// 全局属性
import global from '@/config/global_val';
Vue.prototype.$global = global;

// 引入Vconsole调试工具
import Vconsole from 'vconsole';
if(process.env.NODE_ENV !== 'production' && !!global.isMobile) {
  new Vconsole()  // 所有环境均使用
}

if(!!global.isOverSeas) {
	window.document.title = 'IoT device digital management and application platform';
}else{
	window.document.title = 'IoT设备数字化管理与应用平台';
}

Vue.config.productionTip = false

// 解决滚动条穿透问题
import ModalHelper from './utils/modalHelper'
Vue.prototype.$modalHelper = ModalHelper

// 引入directives
Vue.use((Vue) => {
	((requireContext) => {
		const arr = requireContext.keys().map(requireContext);
		(arr || []).forEach((directive) => {
			directive = directive.__esModule && directive.default ? directive.default : directive;
			Object.keys(directive).forEach((key) => {
				Vue.directive(key, directive[key]);
			});
		});
	})(require.context('./directives', false, /^\.\/.*\.js$/));
});

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
