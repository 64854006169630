export default {
  login: {
    rememberPwd: "Remember",
    forgetPwd: "Forget Password?",
    register: "Registration",
    login_btn: "Log in",
    login_success: "Login success",
    rule: {
      account: "Please enter your account",
      password: "Please enter your password",
      verifyCode: "Verification code",
    }
  },
  register: {
    title: 'Register an account',
    agree_btn: 'Check',
    toProtocol: '《User Service Agreement》',
    toLogin: 'You have an account, Log in now!',
    register_btn: 'Register',
    verifyCode_btn: 'Send',
    verifyCode_btn_again: 'Resend',
    form: {
      '001': 'Account',
      '002': 'Password',
      '003': 'Company',
      '004': 'Name',
      '005': 'Email',
      '006': 'Sales',
      '007': 'User Type',
      '008': 'Phone Number',
      '009': 'SMS Code',
      '010': 'Verification Code',
      '011': 'System Accessed',
    },
    rule: {
      '001': 'Account, Phone, email or English letter',
      '002': 'Please set a 6-20 digit login password',
      '003': 'Please enter your company name',
      '004': 'Please enter your name',
      '005': 'Please enter email address',
      '006': 'Your corresponding sales or agent',
      '007': 'Please select user type',
      '008': 'Please enter mobile number',
      '009': 'SMS verification code',
      '010': 'Please enter Agents',
      '011': 'Click send to get the code in your email!',
      '012': 'Incorrect Phone Number Format',
      '013': 'Incorrect Mailbox Format',
      '014': 'please select System accessed',
    },
    notice: {
      '001': 'Account will be opened for you after approval ',
      '002': 'The page will be automatically redirected to the homepage after seconds. ',
      '003': 'Return to home page ',
    },
    protocol: {
      '001': 'User Service Agreement',
      '002': 'Special Tips: ',
      '003': 'Please read the User Service Agreement (hereinafter referred to as the "Agreement") carefully to ensure that you fully understand the terms of this Agreement. Your registration, login and use will be deemed as acceptance of this Agreement and you agree to be bound by the terms of this Agreement. This Agreement defines the rights and obligations of Users regarding the "IoT Device Digital Management and Application Platform" (hereinafter referred to as the "IoT Platform") software services. "User" refers to individuals who register, log in, and use the "IoT platform". This Agreement may be updated from time to time. Once published, the updated terms of this Agreement will replace the original terms without prior notice. Users may check the latest terms of this Agreement on the registration page "User Service Agreement". After modifying the terms of this Agreement, if users do not accept the revised terms, they shall immediately stop using the "IoT Platform" software services provided by SEUIC Technology Co., Ltd. (hereinafter referred to as "SEUIC"). Users‘ continued use of the "IoT Platform" software services provided by SEUIC will be deemed to have accepted the modified Agreement.',
      '004': 'I. Account registration',
      '005': 'Users need to register an "IoT Platform" account before using the "IoT Platform". The "IoT Platform" account should be registered with an email address, please use an email address that is not yet bound to the "IoT Platform" account. The "IoT Platform" has the right to change the way of account registration and binding according to the user‘s needs or product needs without prior notice to the user.',
      '006': 'Given the bundled registration method of the "IoT Platform" account, you agree that the email address you provide will be used for registration. When users register and use the Service, they need to collect personal information that can identify users so that they can contact users when necessary or provide better user experience. The information collected includes but is not limited to the user’s company, name, email address, etc.',
      '007': 'II. Service Content',
      '008': 'The specific contents of the "IoT Platform" shall be provided based on actual conditions, including but not limited to authorized users to use their accounts to view and download relevant materials, data and other services. SEUIC has the right to adjust the services provided by SEUIC, and the contents of the services provided may change at any time.',
      '009': 'III. User Privacy Information protection',
      '010': 'When registering an account or using "IoT platform" services, users may need to fill in or submit some necessary information, such as identity information required by laws and regulations. If the information submitted by the user is incomplete or does not comply with the provisions of laws and regulations, the user may not be able to use the "IoT platform" or may be restricted in the process of using the "IoT platform". Personal privacy information refers to the information involving a user‘s personal identity or privacy, such as the user‘s real name, ID number, mobile phone number, mobile device identification code, IP address, and chat history. Non-personal privacy information refers to the basic record information clearly and objectively reflected on the server side, such as the user’s operation status and usage habits on the IoT platform, other general information except personal privacy information, and the above privacy information agreed by the user to be disclosed. It is the consistent policy of SEUIC Company to respect the privacy of users‘ personal privacy information. SEUIC Company will take technical measures and other necessary measures to ensure the security of users’ personal privacy information and prevent the disclosure, damage or loss of users‘ personal privacy information collected during the Service. Where there is a possibility of the occurrence of the foregoing, remedial measures will be taken in a timely manner.',
      '011': 'do not disclose or disclose user’s personal privacy information to any third party without user‘s consent. Except for the following specific circumstances: Providing users’ personal privacy information in accordance with laws and regulations or the instructions of competent authorities; Disclosure of any personal information caused by the user informing others of the user password or sharing the registered account and password with others; Disclosure of personal privacy information or other reasons; Users voluntarily disclose their personal privacy information to third parties; Any disclosure of users‘ personal privacy information due to hacker attacks, computer virus intrusion or force majeure events.',
      '012': 'User agrees to use User‘s Personal Privacy Information in the following ways: to send timely material notifications to User, such as software updates or changes to the terms of this Agreement; Internal audit, data analysis and research to improve products, services and communication with customers; Manage, review and deal with user information in accordance with this Agreement; Other matters stipulated by laws and regulations shall apply. In addition to the above, SEUIC will not use Users’ private information for any other purpose without prior consent of users. In order to improve technology and services and provide better service experience to users, the "IoT platform" can collect and use users’ non-personal private information by itself or provide it to third parties.',
      '013': 'IV. Content Specification',
      '014': 'The content mentioned in this article refers to any content produced, edited, copied and transmitted by the user in the process of using the IoT Platform, including but not limited to account profile picture, name and other registration information and authentication information, or text, voice, pictures, graphics and other sharing and related link pages, as well as other content generated by using the account or the IoT Platform. Users shall not use the "IoT Platform" account and services to create, edit, copy, publish, view, or spread information that contains:',
      '015': '1. Inciting to resist or undermine the Constitution and the implementation of laws and regulations;',
      '016': '2. Endangering state security, disclosing state secrets, subverting state power or undermining national unity;',
      '017': '3. Harming the honor and interests of the State;',
      '018': '4. Inciting ethnic hatred, ethnic discrimination and undermining ethnic unity;',
      '019': '5. Sabotaging the state’s religious policies and promoting cults and feudal superstitions;',
      '020': '6. Fabricating or distorting facts, spreading rumors and disturbing social order;',
      '021': '7. Disseminating obscenity, pornography, gambling, violence, murder, terror or inciting crimes;',
      '022': '8. Insult or slander another person or infringe upon the lawful rights and interests of another person;',
      // '023': '9. Failing to comply with the "seven bottom lines" of laws and regulations, the bottom line of the socialist system, the bottom line of national interests, the bottom line of citizens‘ legitimate rights and interests, the bottom line of social and public order, the bottom line of moral fashion and the bottom line of information authenticity;',
      '024': '9. Information containing other contents prohibited by laws and administrative regulations.',
      '025': 'Users shall not use the "IoT Digital Management Platform" account or the Service to create, edit, copy or disseminate the following content that interferes with normal operations or infringes the legitimate rights and interests of other users or third parties:',
      '026': '1. containing any sexual or sexual implication;',
      '027': '2. Contents of abuse, intimidation or threat;',
      '028': '3. Contains harassment, junk advertising, malicious information, decoy information;',
      '029': '4. involving others‘ privacy, personal information or data;',
      '030': '5. Infringe others’ right of reputation, right of portrait, intellectual property, trade secret and other legal rights;',
      '031': '6. Other information that interferes with the normal operation of IoT Platform services and infringes the legitimate rights and interests of other users or third parties.',
      '032': 'V. Rules of Use',
      '033': 'Any content transmitted or posted by Users on the "IoT Platform" does not reflect or represent the views of SEUIC, nor shall it be deemed to reflect or represent the views, positions or policies of SEUIC, and SEUIC is not responsible for any such content. Users shall not use the "IoT Platform" account and services to:',
      '034': '1. Sharing false information, or embezzling the profile picture or data of others, impersonating or using the name of others;',
      '035': '2. Forcing or inducing other users to share information;',
      '026': '3. Misrepresentation and concealment of the truth to mislead and deceive others;',
      '037': '4. Using technical means to establish a batch of false accounts;',
      '038': '5. Using the "IoT Device Digital Management Platform" account or the service to engage in any illegal or criminal activities;',
      '039': '6. Make and publish methods and tools related to the above activities, or operate or disseminate such methods and tools, whether for commercial purposes or not, otherwise violate laws and regulations, infringe on the legitimate rights and interests of other users, or interfere with the activities not expressly authorized by SEUIC Company.',
      '040': 'User shall be responsible for the authenticity, legality, harmlessness, accuracy and validity of the information transmitted using the "IoT Platform" account and services. Any legal liability related to the information transmitted by user shall be borne by User and shall have nothing to do with the "IoT Platform" and "SEUIC Company". If any damage is caused to a third party, the user shall compensate for it according to law.',
      '041': 'VI. Risk-taking',
      '042': 'User understands and agrees that User is responsible for all actions under the registered account, including any content sent by User and any consequences arising therefrom. Users shall exercise their own judgment on the content in the "IoT Platform" and assume all risks arising from their use of the content, including those arising from reliance on the correctness, completeness, or usefulness of the content. SEUIC cannot and will not be liable for any loss or damage resulting from the actions of the User. If you find any person violating this Agreement or using the "IoT Platform" in other improper ways, please immediately report or complain to SEUIC Company, and the company will deal with it in accordance with relevant regulations. Users understand and agree that, due to business development needs, SEUIC reserves the right to unilaterally change, suspend, terminate or revoke all or part of the service content of the "IoT Platform", and Users shall bear such risk.',
      '043': 'VII. Intellectual Property Declaration',
      '044': 'The copyright and trademark rights of all materials or content (including but not limited to data, text, charts, images, sounds or videos) contained in the "IoT Platform", as well as all intellectual property rights including design, shall belong to SEUIC Company, except for the content that is quoted by other parties. No one may copy, disseminate, publish, adapt or display the content of the IoT Platform in any way for any commercial use without SEUIC’s written permission, except for downloading or printing for non-commercial, personal use (subject to the condition that no modification is made and the copyright notes or other ownership notes in the material are retained). In violation of the above statement, SEUIC Company will investigate the relevant legal responsibilities.',
      '045': 'VIII. others',
      '046': 'Solemnly remind users to pay attention to the provisions of this Agreement which exempt users from liability and limit users‘ rights. Please read carefully and consider risks independently. Minors should read this Agreement accompanied by their legal guardian. The validity, interpretation and dispute resolution of this Agreement shall be governed by the laws of the People‘s Republic of China. Any dispute or dispute between User and SEUIC Company shall be settled through friendly negotiation. If negotiation fails, User agrees to submit the dispute or dispute to the people’s court with jurisdiction in the place where SEUIC Company is located. If any provision of this Agreement is invalid or unenforceable for any reason, the remaining provisions shall remain valid and binding upon both parties.',
      '047': 'SEUIC Technology Co., Ltd. reserves the right of final interpretation of this Agreement.',
    },
    systemOptions: {
      '001': 'IoT mainSystem',
      '002': 'IoT mainSystem + MDM subSystem',
    }
  },
  forget: {
    "001": "Retrieve Password",
    "002": "Send",
    "003": "Remember the password",
    "004": "to login now",
    "005": "Reset",
    "006": "Next",
    rule: {
      "001": "Please enter mobile phone number",
      "002": "SMS verification code",
      "003": "Please set a 6-20 digit login password",
      "004": "Please enter your login password again",
      '005': 'Mobile phone number cannot be empty',
      '006': 'The SMS verification code cannot be empty',
      '007': 'Login password cannot be empty',
      '008': 'Two password inputs are different',
      '009': 'Please enter your email address',
      '010': 'Please enter email verification code',
      '011': 'Verify',
      '012': 'Email cannot be empty',
    },
  },
  menus: {
    '000': 'My Favorites',
    "001": "My Message",
    "002": "Change Password",
    "003": "RealName Authentication",
    "004": "Enterprise Certification",
    "005": "Login Records",
    "006": "Message Notification",
    "007": "My Work Order",
  },
  myInfo: {
    title: "Basic Data",
    actualVerify: "To verify your real name",
    companyVerify: "To authenticate the enterprise",
    form: {
      "001": "Account",
      "002": "Number",
      "003": "Type",
      "004": "Name",
      "005": "Company",
      "006": "WeChat",
      "007": "Email",
      "008": "Sex",
    },
    rule: {
      "001": "Please fill in the login account number",
      "002": "Please fill in the mobile phone number",
      "003": "Please fill in your name",
      "004": "Please fill in your real name",
      "005": "Please fill in the company name",
      "006": "Please fill in your WeChat account",
      "007": "Please fill in the email address",
    },
    sexOpts: {
      "001": "male",
      "002": "female",
      "003": "confidential",
    },
    note: {
      "001": "Upload",
      "002": "The size of the image shall be greater than 100 * 100 pixels, supporting jpg, png, jpeg and other formats, and the size shall not exceed 2MB",
    },
  },
  editPwd: {
    title: "Change Password",
    form: {
      "001": "Old Password",
      "002": "New Password",
      "003": "New Password",
    },
    rule: {
      "001": "Please fill in the original login password",
      "002": "Please fill in the new login password",
      "003": "Please enter your password again",
      "004": "Old login password cannot be empty!",
      "005": "New login password cannot be empty!",
      "006": "Please set a 6-20 digit login password",
      "007": "Please enter your password again",
      "008": "The new login password cannot be the same as the old password!",
      "009": "New two login passwords are different!",
    },
    complete: {
      "001": "Password reset succeeded",
      "002": "Please keep your account information safe",
      "003": "Log in again",
    },
  },
  cert: {
    title: "Identity Authentication",
    reason: "Reject Reason:",
    msg: {
      "001": "Authentication data submitted successfully",
      "002": "We will complete the data review as soon as possible",
      "003": "Congratulations! You have completed your personal real name authentication",
      "004": "Real name:",
      "005": "Certificate Number:",
      "006": "Failed to submit authentication data",
      "007": "Please refresh the page to submit the correct ID information",
      "008": "1. The same ID number can only authenticate one account.",
      "009": "2. The information on the national emblem and portrait face of the ID card shall be the same and within the validity period.",
      "010": "3. The image file format can be png, jpg or bmp.",
      "011": "4. The size of a single image should not exceed 3MB and the minimum size should be 500px",
      "012": "5. All uploaded information will be kept safe and will not be used for other commercial purposes or transmitted to other third parties.",
      "013": "6. After submission, it will be manually reviewed and opened for you.",
    },
    form: {
      "001": "ID Card",
      "002": "Name",
      "003": "ID Number",
      "004": "Precautions",
      "005": "Portrait surface",
      "006": "Upload your ID card portrait face",
      "007": "Reupload",
      "008": "National emblem surface",
      "009": "Upload the National emblem of your ID card",
    },
    rule: {
      "001": "Please fill in your name",
      "002": "Please fill in the ID number",
      "003": "ID card portrait surface cannot be empty",
      "004": "The face of the national emblem on the ID card cannot be empty",
      "005": "Name cannot be empty",
      "006": "ID number cannot be empty",
      "007": "ID number is incorrect, please re-enter",
      '008': 'Business license cannot be empty ',
      '009': 'Upload business license can only be JPG and PNG format!',
      '010': 'Upload business license size must not exceed 2MB!',
    },
  },
  enterCert: {
    title: "Enterprise Certification",
    reason: "Reject Reason:",
    msg: {
      "001": "Authentication data submitted successfully",
      "002": "We will complete the data review as soon as possible",
      "003": "Congratulations! You have completed your enterprise certification",
      "004": "Company name:",
      "005": "Taxpayer Identification Number:",
    },
    form: {
      "001": "Business license",
      "002": "Company",
      "003": "Taxpayer Num",
      "004": "Provincial district",
      "005": "Business address",
      "006": "Business scope",
    },
    formPlaceholder: {
      "001": "Please fill in the company name",
      "002": "Please fill in the taxpayer identification number",
      "003": "Please fill in the business address",
      "004": "Please fill in the scope of business",
      "005": "Please upload business license",
      "006": "Provincial district",
      "007": "Please select the province or city",
    },
  },
  loginRecord: {
    title: "Login Record",
  },
  news: {
    title: "Message Notification",
    table: {
      "001": "Notification title",
      "002": "type",
      "003": "Notification time",
      "004": "state",
      "005": "operation",
    },
    stateOpts: {
      "001": "Unread",
      "002": "read",
    },
    labelOpts: {
      '001': 'All Msg',
    },
    detail: {
      'title': 'Msg Detail',
    }
  },
  order: {
    title: "My WorkOrder",
    add: "New WorkOrder",
    table: {
      "001": "WorkOrder number",
      "002": "Title",
      "003": "Problem Classification",
      "004": "Creation time",
      "005": "State",
      "006": "Reply time",
      "007": "Operation",
    },
    stateOpts: {
      "001": "Replied",
      "002": "Submitted",
      "003": "Not submitted",
      "004": "Closed",
    },
  },
  replyOrder: {
    title: "WorkOrder Reply",
    msg: {
      "001": "Basic information",
      "002": "Title:",
      "003": "Work order No. :",
      "004": "Feedback type:",
      "005": "Product Name:",
      "006": "Contact information:",
      "007": "Upload attachment:",
    },
  },
  favorites: {
    title: 'My Favorites',
    table: {
      '001': 'File Name',
      '002': 'Path',
      '003': 'Operations',
    },
    rule: {
      '001': 'Params error',
    }
  }
}