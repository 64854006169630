export default {
  banner: {
    title: '产品营销工具',
    subTitle: '丰富详实的营销资料库，助力产品宣传推广',
  },
  search: {
    searchPlaceholder: '请输入要搜索的资料标题',
  },
  download: {
    breadcrumb: {
      '001': '营销工具',
      '002': '搜索内容'
    },
    table: {
      '001': '资料标题',
      '002': '素材名称',
      '003': '格式',
      '004': '大小(MB)',
      '005': '下载次数',
      '006': '最后更新',
      '007': '操作',
    }
  },
  companyPresent: {
    breadcrumb: {
      '001': '营销工具',
      '002': '资料列表展示'
    },
    search: {
      '001': '条',
      '002': '产品线',
      '003': '产品名称',
    },
    table: {
      '001': '资料标题',
      '002': '素材名称',
      '003': '格式',
      '004': '大小(MB)',
      '005': '下载次数',
      '006': '最后更新',
      '007': '操作',
    }
  }
}