export default {
  banner: {
    title: "Product Marketing Tool",
    subTitle: "Rich and detailed marketing database to facilitate product promotion",
  },
  search: {
    searchPlaceholder: "Please enter the title of the data to be searched",
  },
  download: {
    breadcrumb: {
      "001": "Marketing tool",
      "002": "Search content",
    },
    table: {
      "001": "Data title",
      "002": "Material name",
      "003": "Format",
      "004": "Size (MB)",
      "005": "Number of downloads",
      "006": "Last update",
      "007": "Operation",
    },
  },
  companyPresent: {
    breadcrumb: {
      "001": "Marketing tool",
      "002": "Information List Presentation",
    },
    search: {
      "001": "items",
      "002": "Product line",
      "003": "Product name",
    },
    table: {
      "001": "Data title",
      "002": "Material name",
      "003": "Format",
      "004": "Size (MB)",
      "005": "Number of downloads",
      "006": "Last update",
      "007": "Operation",
    },
  },
};
