import Vue from 'vue'
import Vuex from 'vuex'
import VueCookie from 'vue-cookie'
import { getMenus } from '@/api/common';
import { getLoginUser } from '@/api/user';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // activeIndex: null,
    userInfo: null,
    menus: null,
    // article: null,
    deviceType: 'pc',
  },
  getters: {
    // getActiveIndex: state => {
    //   if(!state.activeIndex) {
    //       try {
    //           const activeIndex = window.localStorage.getItem(process.env.VUE_APP_ACTIVEINDEX) || '/'
    //           state.activeIndex = JSON.parse(activeIndex)
    //       } catch(e) {
    //           console.error(e);
    //       }
    //   }
    //   return state.activeIndex
    // },
    getUserInfo: state => {
      if(!state.userInfo) {
          try {
            const userInfo = window.localStorage.getItem(process.env.VUE_APP_USERINFO);
            state.userInfo = JSON.parse(userInfo);
          } catch(e) {
              console.error(e);
          }
      }
      return state.userInfo
    },
    getMenus: state => {
      if(!state.menus) {
          try {
              const menus = window.localStorage.getItem(process.env.VUE_APP_MENUS)
              state.menus = JSON.parse(menus)
          } catch(e) {
              console.error(e);
          }
      }
      return state.menus
    },
    // getArticle: state => {
    //   if(!state.article) {
    //     try {
    //       const article = window.localStorage.getItem(process.env.VUE_APP_ARTICLE)
    //       state.article = JSON.parse(article)
    //     } catch(e) {
    //         console.error(e);
    //     }
    //   }
    //   return state.article;
    // },
    getDeviceType: state => {
      if(!state.deviceType) {
        try {
          const deviceType = window.localStorage.getItem(process.env.VUE_APP_DEVICETYPE)
          state.deviceType = JSON.parse(deviceType)
        } catch(e) {
            console.error(e);
        }
      }
      return state.deviceType;
    }
  },
  mutations: {
    // setActiveIndex: function(state, activeIndex) {
    //   if(state.activeIndex !== activeIndex) window.sessionStorage.removeItem(process.env.VUE_APP_TABINDEX)
    //   state.activeIndex = activeIndex;
    //   window.localStorage.setItem(process.env.VUE_APP_ACTIVEINDEX, JSON.stringify(activeIndex));
    // },
    setUserInfo: function(state, userInfo) {
      state.userInfo = userInfo;
      if(userInfo === '') {
        window.localStorage.removeItem(process.env.VUE_APP_USERINFO);
        VueCookie.delete(process.env.VUE_APP_USERINFO);
      } else {
        window.localStorage.setItem(process.env.VUE_APP_USERINFO, JSON.stringify(state.userInfo));
        VueCookie.set(process.env.VUE_APP_USERINFO, JSON.stringify({username: state.userInfo.username}), {expires: 1, path: '/'});
      }
    },
    setMenus: function(state, menus) {
      state.menus = menus;
      if(menus === '') {
        window.localStorage.removeItem(process.env.VUE_APP_MENUS);
      } else {
        window.localStorage.setItem(process.env.VUE_APP_MENUS, JSON.stringify(menus));
      }
    },
    setProps: function(state, props) {
      const { name, key, value } = props;
      state[name][key] = value;
    },
    // setArticle: function(state, arts) {
    //   if(!arts) {
    //     state.article = null;
    //     window.localStorage.removeItem(process.env.VUE_APP_ARTICLE);
    //   } else {
    //     state.article = arts;
    //     window.localStorage.setItem(process.env.VUE_APP_ARTICLE, JSON.stringify(arts));
    //   }
    // },
    setDeviceType: function(state, type) {
      state.deviceType = type;
    },
  },
  actions: {
    // 获取用户信息
    async findUserInfo({dispatch, commit}) {
      await getLoginUser().then(res => {
        if(res.rescode === 200) {
          commit('setUserInfo', res.data);
        }
      })
      await dispatch('findMenus');
    },
    // 获取权限菜单
    async findMenus({commit}) {
      await getMenus({platformId:20,type:1}).then(res => {
        if(res.rescode === 200) {
          commit('setMenus', res.data);
        }
      })
    },
    // // 获取用户信息
    // findUserInfo({dispatch, commit}) {
    //   return new Promise((resolve, reject) => {
    //     getLoginUser().then(res => {
    //       if(res.rescode === 200) {
    //         commit('setUserInfo', res.data);
    //         dispatch('findMenus').then(res => {
    //           resolve();
    //         }).catch(e = reject(e))
    //       }
    //     }).catch(e => reject(e));
    //   });
    // },
    // // 获取权限菜单
    // findMenus({commit}) {
    //   return new Promise((resolve, reject) => {
    //     getMenus({platformId:20,type:1}).then(res => {
    //       if(res.rescode === 200) {
    //         commit('setMenus', res.data);
    //         resolve(res.data);
    //       }
    //     }).catch(e => reject(e));
    //   });
    // }
  },
  modules: {
  }
})
