import enLocale from "element-ui/lib/locale/lang/en";
import common from "./common";
import home from "./home";
import solution from "./solution";
import marketplace from "./marketplace";
import marketing from "./marketing";
import developer from "./developer";
import support from "./support";
import user from "./user";
import code from "./code";

export default {
  translation: {
    ...enLocale,
    common,
    home,
    solution,
    marketplace,
    marketing,
    developer,
    support,
    user,
    code
  }
};
