let api = {
    // 公共接口
    AllNoticeList:'/front/home/getAllNoticeList', //全部公告列表
    NoticeDetail:'/front/home/noticeDetail', //公告详情
    UpdateReadNum:'/front/article/updateReadNum', //更新文章阅读数量
    RecordHistory:"/front/searchRecord/searchRecordHistory", //用户搜索历史记录
    PermissionTree: '/permission/get_permission_tree_for_layui', // 权限列表
    UnreadMessage: '/front/fmsg/getUnreadMessage', // 未读消息
    DownloadFiles:'/front/download/downLoadFile', //下载文件统一接口
    SetLanguage: '/user/setLang', //设置语言
    GetLanguage: '/settings/getLang', //获取当前语言环境
    GetShareLink: '/front/shareLink/getLink', // 分享链接
    OpenShareLink: '/front/shareLink/openLink', // 打开链接
    AddFavorite: '/front/favorites/add', // 添加收藏
    DeleteFavorite: '/front/favorites/delete', // 取消收藏
    GetFavoriteList: '/front/favorites/list', // 获取收藏列表
    TopFavorite: '/front/favorites/updateTop', // 置顶收藏

    // 用户相关
    Login: '/user/login', // 登录
    Register: '/front/user/register', // 注册
    Logout: '/user/logout', // 退出
    SendCodeWithEmail: '/emailCode/send', // 发送邮箱验证码
    CheckEmail: '/front/user/checkEmail', // 核对邮箱
    SendCode: '/mobileCode/send', // 发送手机验证码
    CheckPhone: '/front/user/checkphone', // 核对手机号
    UpdatePassword: '/front/user/updatePassword', // 修改密码
    UpdatePwd: '/front/user/newPassword', // 修改密码-忘记密码页面
    LoginUser: '/user/get_login_user', //获取用户信息
    GetUser: '/front/user/getuser', // 获取用户信息
    UpdateUser: '/front/user/updateUser', //更新用户信息
    Verify: '/front/user/verification', // 用户验证
    Verification:'/front/customers/verification', //企业认证
    GetCustomer:'/front/customers/getCustomer', //获取企业信息
    GetAreaElement:'/dataDictionary/getAreaElement', //省市区
    GetloginLog:"/front/login/getloginLog", //获取登录日志
    UpdateMsgState: '/front/fmsg/updateMsgState',
    GetMsgList: '/front/fmsg/msgList',
    GetOrder: '/front/forder/getMsg',
    GetOrderList: '/front/forder/msgList',
    SubmitMsg: '/front/forder/submitMsg', //


    // 首页相关
    BannerList: '/front/banner/list', // 首页轮播图
    PlatformList: '/front/home/platformList', // 首页系统展示
    RecCaseList: '/front/home/recCaseList', // 首页推荐方案
    NewNoticeList: '/front/home/newNoticeList', // 首页最新公告
    MyApplications: '/front/home/myApplications', // 首页我的应用
    BoutiqueApplication: '/front/home/boutiqueApplication', // 首页推荐应用
    GetUserBy:"/front/user/getuserby", //获取管理账号数据
    HomeFaq:'/front/article/homeFAQ', //常见问题
    DevCount: "/front/user/getdevcount", //获取设备数量
    MDMTry: "/front/home/mdmTry", // 立即使用MDM
    
    
    // 解决方案
    ClassifyList: '/front/classify/list', // 获取层级目录
    ProductClassifyList:'/front/classify/productList',
    ProductFirstList:'/front/classify/productListByFirst',
    CaseDetail:'/front/solutionCase/getCaseDetail', //解决方案详情
    GetDataDictionaryInCtgKey:'/front/solutionCase/industry',
    SolutionCaseList:'/front/solutionCase/solutionCaseList',
    GetEquipmentList:'/front/solutionCase/getEquipmentList', //应用市场-适用设备方案列表（旧方案）
    GetEquipmentListNew:'/front/solutionCase/getEquipmentListNew', //应用市场-适用设备方案列表（新方案）
    AppEquipment:'/front/solutionCase/getAppEquipment',//适用设备
    CaseDownLoad:'/front/solutionCase/getCaseFiles', //方案资料下载


    // 应用市场
    AppList: '/front/apps/appList', //
    EcologyAppDetail:'/front/apps/detailApp', //应用市场生态软件详情
    AppClassification: '/front/apps/classification',
    EcologicalSoftware: '/front/apps/ecologicalSoftware',
    ProductList: '/front/apps/productList',
    GetCheckingData:'/front/apps/getCheckingData', //适用设备查看资料
    GetProductSuperior:'/front/apps/getProductSuperior', //根据产品ID查询上级
    AppDownload: '/front/apps/appInfoFiles', //应用市场下载


    // 营销工具
    Appslist:"/front/fapps/appslist",//应用上架
    GetMarketList:'/front/market/list', //营销分类列表
    FliesList:'/front/market/marketList', //文件列表
    MetarialType: '/material/getMetarialType', //
    AboutProduct:'/front/classify/aboutProduct', //判断资料产品关联
    MarkProductList:'/front/classify/markProductList', //营销工具产品资料产品
    GetFileCtg: '/material/getFileCtg', // 获取二级分类


    // 开发者
    Del_status:"/front/fapps/del_status", //逻辑删除
    AppCateList: '/dataDictionary/getDataDictionaryInCtgKey',
    Files:"/front/fapps/files", //获取文件
    SubmitAppps:"/front/fapps/submitAppps", //应用提交
    Appsverlist:"/front/fappsver/appsverlist", //版本更新
    SubmitUpdate:"/front/fappsver/submitUpdate",
    Fabu:"/front/fappsver/fabu", // 应用发布
    SubmitApppsVer:"/front/fappsver/submitApppsVer",
    Getapicare:"/front/fcategroy/getapicare", //获取api接口类别
    ApiList:"/front/api/apiList", //api接口
    Getcategroy:"/front/fcategroy/getcategroy", //获取开发者开发文档
    Wendang:"/front/fsdk/wendang", // 获取SDK分类
    GetParameter: '/front/fsdk/getParameter', // 
    SdkList:"/front/fsdk/sdkList", //sdk通用
    Sdkmodellist:"/front/fsdk/sdkmodellist", //机型专用
    SdkProduct:"/front/fsdk/sdkProduct", //获取机型专用数据
    SdkProductNull:"/front/fsdk/sdkProductNull", // 获取产品列表
    FileList:"/front/fsdk/fileList", // 获取文件列表
    Diction: '/dataDictionary/getDataDictionaryInCtgKey',
    SelectList:"/front/test/selectList", //测试包
    FindArticle: '/front/article/getById', // 查询单个文章


    // 服务支持
    ArticleClassList:'/front/fcategroy/queryCategoryJson', //文章分类列表
    ArticleExhibitList:'/front/article/articleList', //文章展示列表
    HotKeyList:'/front/article/hotKeyWords', //热门关键词字典方案
    HostQuestion:'/front/article/hotQuestion', //热搜问题
    OrderType:'/front/forder/orderType', //工单类型
    SubmitOrder:'/front/forder/submitOrder', //提交工单


    // 其他-未使用
    ProductTree:'/front/classify/productTree',
    // Upload:config.BaseUrl + "api/file/upload.do", //上传
    GetBusId: '/front/upload/getBusId', //获取上传业务编号
    JumpHtml:'/front/market/jumpHtml', //页面跳转
    FindDeviceCount:"/MDMServer/iot/findDeviceCount", //首页查看设备数量
}

export default api