import request from '@/utils/request';
import api from './api';

export function getMenus(data) {
  return request({
    url: api.PermissionTree, 
    data, 
    method: 'get'
  })
}

export function getUnreadMessage(data) {
  return request({
    url: api.UnreadMessage, 
    data,
    method: 'get'
  })
}

export function getRecordHistory(data) {
  return request({
    url: api.RecordHistory, 
    data, 
    method: 'get'
  })
}

// notice
export function getNoticeList(data) {
  return request({
    url: api.AllNoticeList,
    data, 
    method: 'get'
  })
}

export function getNoticeDetail(data) {
  return request({
    url: api.NoticeDetail,
    data, 
    method: 'get'
  })
}

export function updateReadNum(data) {
  return request({
    url: api.UpdateReadNum,
    data, 
    method: 'get'
  })
}

// 语言设置
export function setLang(data) {
  return request({
    url: api.SetLanguage + '?language=' + data,
    method: 'post',
  })
}

// 获取当前语言环境
export function getLang() {
  return request({
    url: api.GetLanguage,
    method: 'get',
  })
}

// 获取分享链接
export function getLink(data) {
  return request({
    url: api.GetShareLink,
    method: 'post',
    data,
  })
}
// 打开分享链接
export function openLink(data) {
  return request({
    url: api.OpenShareLink,
    method: 'get',
    data,
  })
}