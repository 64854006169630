export default {
  banner: {
    title: "Service Support Center",
    subTitle: "Frequently asked questions, service order, provide users with convenient and efficient service support",
  },
  article: {
    title: "Frequently Asked Questions",
    operation: {
      "001": "View details",
    },
    hotTitle: "Hot search question",
    hotKey: "Hot Keywords",
    history: "Search history",
  },
  order: {
    title: "Service order",
    subTitle: "Submit order",
    note: {
      '001': "Mandatory",
      '002': 'Submitted successfully'
    },
    table: {
      "001": "Feedback type",
      "002": "Title",
      "003": "Product name",
      "004": "Detail description",
      "005": "Upload attachment",
      "006": "Contact information",
      "007": "Submit order",
    },
    rules: {
      '001': 'Please select work order type ',
      '002': 'Please enter the title ',
      '003': 'Please enter product name ',
      '004': 'Please enter contact information ',
      '005': 'Detail description cannot be empty ',
      },
    placeholder: {
      "001": "Please fill in the title",
      "002": "Please fill in the product name",
      "003": "Please enter content",
      "004": "Please fill in the contact information",
    },
  },
};
