export default {
  database: {
    title: '我的数据',
    '001': '设备数量',
    '002': '已购应用',
    '003': '管理账号',
    '004': '未读消息',
  },
  platForm: {
    title: '我的管理系统',
    empty: '你还没有添加相应系统呢',
    join: '进入系统',
    apply_try: '申请试用',
    cancel_try: '取消试用',
    try: '立即试用',
    try_notice_content1: '申请后可进入MDM系统，若需注册设备使用，',
    try_notice_content2: '需联系您的销售/售前经理/代理商开通设备数量授权。',
    try_notice_title: '申请提示',
  },
  recCase: {
    title: '推荐方案',
  },
  notice: {
    title: '最新公告',
    breadcrumb: {
      '001': '首页',
      '002': '公告列表',
      '003': '公告详情',
    },
    table: {
      '001': '公告标题',
      '002': '创建时间',
      '003': '操作',
    },
    detail: {
      '001': '发布时间：',
    }
  },
  myApp: {
    title: '我开发的应用',
    release: '上架',
    unRelease: '未上架',
  },
  recApp: {
    title: '推荐应用'
  },
  problem: {
    title: '常见问题',
    more: '查看更多'
  },
  banner: {
    empty: '暂无添加任何图片～'
  },
  view: '立即查看',
  workbench: {
    title: '工作台',
    myPlatForm: '我的管理系统',
    myData: '我的业务数据',
    myApp: '我开发的应用',
  },
}