export default {
  lang: 'English',
  zh: '简体中文',
  en: 'English',
  intl: 'International',
  china: '中国站',
  header: {
    title: 'IoT Platform',
    subTitle: 'Seuic Official Website',
    home: 'Home',
    solution: 'Solution',
    marketplace: 'Application Market',
    marketing: 'Marketing Tools',
    developer: 'Developer',
    support: 'Support',
    msg: 'Message',
    login: 'Please login first',
    logout: 'Log Out',
    myInfo: 'My Information',
    model_selection: 'Model Selection',
    login_btn: 'login',
    register_btn: 'register',
    favorite: 'My Favorites',
  },
  search: 'Search',
  searchPlaceholder: 'Please enter content to search and click on each category tab to switch to find it',
  searchRes: {
  '001': "Contains'",
  '002': "', The search results for'",
  '003': "'Bars",
  },
  list: 'List',
  back: 'back',
  empty: 'No data available',
  add: 'Added',
  edit: 'Edit',
  delete: 'Delete',
  update: 'Update',
  find: 'Enquiry',
  detail: 'Details',
  buy: 'Buy Now',
  download: 'Download',
  preview: 'Preview',
  loading: 'Loading',
  failedLoad: 'Loading failed',
  findInfo: 'View profile',
  save: 'Save',
  cancel: 'Cancel',
  find: 'Find',
  open: 'Open',
  close: 'Close',
  send: 'Send',
  submit: 'Submit',
  success: 'success',
  fail: 'fail',
  select: 'Select',
  refresh_success: 'refresh successfully',
  copy_success: 'copied successfully',
  copy_fail: 'copy failed',
  copy: 'Copy',
  submit_success: 'submitted successfully',
  check_detail: 'Details',
  operate_success: 'Operate successfully',
  add_success: 'Added successfully',
  edit_success: 'Modified successfully',
  delete_success: 'Delete successfully',
  save_success: 'Save successfully',
  clear_success: 'Clear successfully',
  operate_fail: 'Operation failed',
  operate_warn: 'Operation Warning',
  system_warn: 'System tips',
  ok_text: 'OK',
  cancel_text: 'Cancel',
  confirm_continue_text: 'Are you sure you want to continue?',
  confirm_delete_text: 'Are you sure you want to delete?',
  cancel_operate_text: 'Operation cancelled',
  saveAndNoSubmit: 'Save hold on submission',
  submitCheck: 'Submit for review',
  direct_download: 'download',
  direct_preview: 'preview',
  share: 'Share',
  logout: {
    '001': 'Please confirm whether to log out again',
    '002': 'Logout failed, please try again later...',
  },
  footer: {
    home: 'Home',
    home_one: 'Notice',
    solution: 'Solution',
    solution_one: 'Solution',
    solution_two: "Device Solution",
    marketplace: 'Application Market',
    marketplace_one: 'Eco software',
    marketplace_two: 'Adaptation Software',
    marketing: 'Marketing Tools',
    developer: 'Developer',
    developer_one: 'Application',
    developer_two: 'Capabilities',
    developer_three: 'Documentation',
    support: 'Support',
    support_one: 'Questions',
    support_two: 'Work Order',
    support_three: 'Model Selection',
    gov: 'Copyright © 2014-2024 SEUIC Technologies Co., Ltd. All rights reserved',
    record: 'Su ICP 11034044-7',
    phoneNum: 'Contact us: ',
    email: 'Contact Email: ',
    modelSelect: 'Model Selection',
    code: {
      '001': 'Scan the code to follow',
      '002': 'WeChat Official Account',
    },
    contact: 'Contact us',
  },
  note: {
    '001': 'Register successfully',
    '002': 'Network request failed',
    '003': 'The verification code is successfully obtained, please pay attention to the receipt',
    '004': 'Data saved successfully',
    '005': 'Password changed successfully',
    '006': 'Failed to log out. Please try again later..',
    '007': 'Saved successfully and submitted for review!',
    '008': 'Failed to view details, user is not logged in',
    '009': 'I‘m sorry! You do not have permission to access this resource.',
    '010': 'No permission, please contact admin',
    '011': 'No relevant article content',
    '012': "No permission for now ",
    '013': 'No corresponding document',
    '014': 'Upload-only apk files',
    '015': 'The size of the uploaded file must not exceed 150MB!',
    '016': 'Installation package cannot be empty!',
    '017': 'Version number cannot be empty!',
    '018': 'Version name cannot be empty!',
    '019': 'Updates cannot be empty!',
    '020': 'The uploaded images can only be in JPG or PNG format!',
    '021': 'The size of the uploaded image should not exceed 2MB!',
    '022': 'Upload images in JPG or PNG format or video format only!',
    '023': 'The size of the uploaded image or video must not exceed 100MB!',
    '024': 'Preview of this format is not supported at this time',
    '025': 'Login credentials expired, please log in again',
    '026': 'Operation failed, user is not logged in',
    '027': 'Failed to submit work order, user is not logged in',
    '028': 'Failed to download file, user is not logged in',
    '029': 'Failed to preview file, user is not logged in',
    '030': 'Failed to upload file, user is not logged in',
    '031': 'Please contact SEUIC’s Sales',
    '032': 'Upload images only in JPG, PNG, JPEG, GIF or video format!',
    '033': 'The file is too large. You are advised to download it for preview',
    '034': 'Jump failed, please login first',
    '035': 'There are no images to preview on this page',
  },
  loading: 'Loading...',
  notice: 'Notice',
  loadMore: 'Load More',
  new: 'new',
  prevPage: 'prev',
  nextPage: 'next',
  shareDialog: {
    title: 'Share',
    form: {
      '001': 'Whether Login:',
      '002': 'Need to log in to the IoT platform',
      '003': '(Jump to the login/registration page, and jump to the specified page after logging in to the account)',
      '004': 'No login required',
      '005': '(Directly enter the specified page to view and download, valid for 7 days)',
    },
    cancel: 'Cancel',
    ok: 'copy address to share',
  },
  get_link: 'Get Link',
  share_link: 'Click and Get Link',
  favorite: 'Favorite',
  favorited: 'Favorited',
  cancel_favorite: 'Cancel favorite',
  cancel_favorite_success: 'Cancel favorite successfully',
  favorite_success: 'Favorite successfully',
  top: 'Top',
  cancel_top: 'Cancel Top',
  top_success: 'Top successfully',
  cancel_top_success: 'Cancel top successfully',
  operation: 'Operation',
  batch_preview: 'Batch preview',
  apps_list: 'Software List',
  sdk_list: 'SDK List',
  test_package_list: 'Development Recourse List',
};